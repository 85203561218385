import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Optional,
} from '@angular/core';
import { IonRouterOutlet, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { LoadingService } from 'src/app/modules/shared/components/loading/loading.service';
import { App as CapacitorApp } from '@capacitor/app';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  @Input()
  role: 'admin' | 'user' | 'anonymous';

  loadingSubscription: Subscription;
  loading = false;
  constructor(private loadingService: LoadingService) {
    this.loadingSubscription = this.loadingService.subject.subscribe(
      (value) => {
        this.loading = value;
      }
    );
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.loading = false;
    this.loadingSubscription?.unsubscribe();
  }
}
