import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TicketService {
  constructor(private http: HttpClient) {}

  scanTicket(imageBase64: { image: string }) {
    return this.http.post<any>(
      `${environment.baseApiUrl}/ocr/ticket`,
      imageBase64
    );
  }

  scanTicketInGoogleVision(imageBase64: { image: string }) {
    return this.http.post<any>(
      `${environment.baseApiUrl}/ocr/ticket/googlevision`,
      imageBase64
    );
  }

  addTicket(email: string, id: string, data, ticket: File) {
    let ticketName = data.ticketName;
    let ticketNumber = data.ticketNumber;
    let raffleNumber = data.raffleNumber;
    let formParams = new FormData();
    formParams.append('ticket', ticket);
    formParams.append('ticketName', ticketName);
    formParams.append('ticketNumber', ticketNumber);
    formParams.append('raffleNumber', raffleNumber);
    return this.http.post<any>(
      `${environment.baseApiUrl}/ticket/` + email + `/ticket-upload/` + id,
      formParams
    );
  }

  addScratchCard(syndicateId: string, file: File, price: number) {
    let formParams = new FormData();
    formParams.append('file', file);
    return this.http.post<any>(
      `${environment.baseApiUrl}/ticket/scratch/card/upload/${syndicateId}/${price}`,
      formParams
    );
  }

  getAllTickets() {
    return this.http.get<any>(`${environment.baseApiUrl}/ticket`);
  }

  getTicket(syndicateId: string) {
    return this.http.get<any>(
      `${environment.baseApiUrl}/ticket/get-ticket/` + syndicateId
    );
  }

  getDailyMillionTicket(syndicateId: string) {
    return this.http.get<any>(
      `${environment.baseApiUrl}/ticket/get-ticket/${syndicateId}/get-dailymillionurl`
    );
  }

  getTicketUrl(syndicateId: string) {
    return this.http.get<any>(
      `${environment.baseApiUrl}/ticket/get-ticket/` + syndicateId + `/get-url`
    );
  }

  getCurrentTicketsOfUser(email: string, uid: string) {
    return this.http.get<any>(
      `${environment.baseApiUrl}/ticket/user/` +
        email +
        `/current/groups/` +
        uid
    );
  }

  deleteTicket(syndicateId: string) {
    return this.http.delete<any>(
      `${environment.baseApiUrl}/ticket/delete/ticket/` + syndicateId
    );
  }

  deleteResult(syndicateId: string) {
    return this.http.delete<any>(
      `${environment.baseApiUrl}/ticket/delete/result/` + syndicateId
    );
  }

  deleteDailyMillionResult(syndicateId: string) {
    return this.http.delete<any>(
      `${environment.baseApiUrl}/ticket/delete/dailymillions/result/` +
        syndicateId
    );
  }

  getCurrentDate() {
    return Date.now();
  }
}
