import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NotificationModel } from 'src/app/services/content-model/notification-model/notification-model';
import { ToastService } from 'src/app/services/toast.service';
import { NotificationsService } from '../../services/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  providers: [DatePipe],
})
export class NotificationComponent implements OnInit {
  @Input()
  notifications: NotificationModel;

  constructor(
    private modalController: ModalController,
    private notificationService: NotificationsService,
    private toastService: ToastService
  ) {}

  ngOnInit() {}

  onClose() {
    let id = this.notifications?.id;
    this.modalController.dismiss({
      dismissed: true,
      notificationId: id,
    });
  }
}
