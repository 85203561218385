import {
  Component,
  //ElementRef,
  //HostListener,
  Input,
  OnInit,
  //ViewChild,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LoginComponent } from 'src/app/components/login/login.component';
import { SignupComponent } from 'src/app/components/signup/signup.component';
import { AuthService } from 'src/app/services/auth.service';
import { navItems } from 'src/app/constants/nav-options-constants';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { ForgotPasswordComponent } from 'src/app/components/forgot-password/forgot-password.component';
import IUser, { UserService } from '../../services/user.service';
import { sideNavItems } from 'src/app/constants/side-nav-constants';
import { ProfilePhotoComponent } from '../profile-photo/profile-photo.component';
import { filter } from 'rxjs/operators';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { disclaimerUrl } from 'src/app/constants/disclaimer-url';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  @Input() isDesktop = false;
  @Input() config: INavConfig;
  desktop = false;
  url: string;
  title: string;
  user: IUser;
  userLogged = false;
  editIcon = false;
  imageInDb: string;
  firstName: string;
  lastName: string;
  params: string;
  exitButton = false;
  email: string;
  privacyPolicyUrl = disclaimerUrl.privacyPolicy;
  termsAndConditionsUrl = disclaimerUrl.termsAndConditions;
  userAgreementUrl = disclaimerUrl.userAgreement;
  faqUrl = disclaimerUrl.faq;
  native: boolean;

  constructor(
    private modalController: ModalController,
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute
  ) {
    if (Capacitor.getPlatform() !== 'web') {
      this.native = true;
    }
  }

  ngOnInit() {
    this.getUser();
    this.userService.currentUser.subscribe((res: IUser) => {
      this.imageInDb = res?.userDetails?.profilePhoto;
      this.email = res?.userDetails?.email;
      this.firstName = res?.userDetails?.firstname;
      this.lastName = res?.userDetails?.lastname;
    });
    if (Capacitor.getPlatform() !== 'web') {
      this.exitButton = true;
    }
  }

  timeOutFunction(id: string) {
    setTimeout(() => {
      this.onTabClick(id);
    }, 5000);
  }

  onTabClick(id: string) {
    if (id == '#contact') {
      this.router.navigate(['/contact-us']);
    }
    let currentUrl = this.router.url;
    if (currentUrl.indexOf('/home') == -1) {
      if (id == '#contact') {
        this.router.navigate(['/contact-us']);
      } else {
        this.router.navigate([`/home`], { queryParams: { id: id } });
      }
    } else {
      document
        .querySelector(id)
        .scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  exitApp() {
    CapacitorApp.exitApp();
  }

  getUser() {
    this.user = this.userService.currentUser.getValue();
    if (this.user.email) {
      this.userLogged = true;
    }

    this.defaultTitle();
  }

  onMouseOver(event: any) {}

  async showRegister() {
    const signupModal = await this.modalController.create({
      component: SignupComponent,
      cssClass: this.desktop ? 'modal-popup-login' : 'modal-popup-mobile',
    });

    return signupModal.present();
  }

  async showLogin() {
    const loginModal = await this.modalController.create({
      component: LoginComponent,
      cssClass: this.desktop ? 'modal-popup-login' : 'modal-popup-mobile',
    });
    return loginModal.present();
  }

  async showForgotPassword() {
    const passwordModal = await this.modalController.create({
      component: ChangePasswordComponent,
      cssClass: 'change-password',
    });
    await passwordModal.present();
    return false;
  }

  signOut() {
    this.authService.signOut();
  }

  menu(item) {
    if (item.id == navItems.password) {
      this.showForgotPassword();
    } else if (item.id == navItems.logout) {
      this.router.navigate(['/']);
      this.signOut();
    }
  }

  sideMenu(item) {
    if (item.id == sideNavItems.home) {
      this.router.navigate(['/']);
      this.title = item.title;
    } else if (item.id == sideNavItems.password) {
      this.showForgotPassword();
    } else if (item.id == sideNavItems.logout) {
      this.router.navigate(['/']);
      this.signOut();
    } else if (item.id == sideNavItems.notifications) {
      this.router.navigate(['/user/notifications']);
      this.title = item.title;
    } else if (item.id == sideNavItems.profile) {
      this.router.navigate(['/user/profile']);
      this.title = item.title;
    } else if (item.id == sideNavItems.ticket) {
      this.router.navigate(['/user/tickets']);
      this.title = item.title;
    } else if (item.id == sideNavItems.transaction) {
      this.router.navigate(['/user/transactions']);
      this.title = item.title;
    } else if (item.id == sideNavItems.wallet) {
      this.router.navigate(['/user/wallet']);
      this.title = item.title;
    } else if (item.id == sideNavItems.ticketUpload) {
      this.router.navigate(['/admin/ticket-upload']);
      this.title = item.title;
    } else if (item.id == sideNavItems.uploadResults) {
      this.router.navigate(['/admin/upload-results']);
      this.title = item.title;
    }
  }

  defaultTitle() {
    if (this.user) {
      if (this.user.role == 'user') {
        this.title = 'MY PROFILE';
      } else if (this.user.role == 'admin') {
        this.title = 'DASHBOARD';
      }
    }
  }

  async showProfilePhoto() {
    const profilePhotoModal = await this.modalController.create({
      component: ProfilePhotoComponent,
      cssClass: 'profile-photo-container',
    });
    await profilePhotoModal.present();
    return false;
  }

  mouseEnter() {
    this.editIcon = true;
  }

  mouseLeave() {
    this.editIcon = false;
  }
}

export interface INavConfig {
  logoUrl: string;
  navItems: INavItem[];
  mobileSideNavItems: INavItem[];
  title?: string;
  profile?: {
    displayName: string;
    imageUrl: string;
    navItems: INavItem[];
  };
}

interface INavItem {
  icon?: string;
  title?: string;
  url?: string;
  navigate?: string;
  data?: any;
  navItems?: INavItem[];
}
