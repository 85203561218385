import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { AlertService } from 'src/app/services/alert.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
})
export class OtpComponent implements OnInit {
  otpFormContainer = false;
  mobileNumberContainer = true;
  phoneNumber: string;
  userEmail: string;
  constructor(
    private modalController: ModalController,
    private userService: UserService,
    private toastService: ToastService,
    private alertServie: AlertService
  ) {}

  ngOnInit() {
    this.phoneNumber =
      this.userService.currentUser.getValue().userDetails?.number;
    this.userEmail = this.userService.currentUser.getValue().email;
    this.renderReCaptchaVerifier();
  }

  otpForm = new FormGroup({
    otp: new FormControl('', [Validators.required]),
  });

  renderReCaptchaVerifier() {
    (window as any).recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'get-otp',
      {
        size: 'invisible',
        callback: (recaptchaToken: any) => {
          this.sendOtp(recaptchaToken);
        },
      }
    );

    (window as any).recaptchaVerifier.render().then(function (widgetId: any) {
      (window as any).recaptchaWidgetId = widgetId;
    });
  }

  showOtpInput() {
    this.otpFormContainer = true;
    this.mobileNumberContainer = false;
  }

  async showNumberInput() {
    this.modalController.dismiss({
      dismissed: true,
    });
    const otpModal = await this.modalController.create({
      component: OtpComponent,
      cssClass: 'otp-verify',
    });
    return otpModal.present();
  }

  sendOtp(recaptchaToken: string) {
    this.userService
      .sendOtp(this.userEmail, recaptchaToken, this.phoneNumber)
      .subscribe((res) => {});
  }

  verifyOtp(data) {
    this.userService.verifyOtp(this.userEmail, data.otp).subscribe(
      (res) => {
        this.toastService.toast('Your mobile number verified successfully.');
        this.modalController.dismiss({
          dismissed: true,
        });
      },
      (error) => {
        this.alertServie.alert(error);
      }
    );
  }

  onClose() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
