import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RoutesRecognized,
} from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { Platform } from '@ionic/angular';
import { Subject, Subscription } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { SplashService } from './components/splash/splash.service';
import { LoadingService } from './modules/shared/components/loading/loading.service';
import {
  PaymentService,
  PlanPrice,
} from './modules/shared/services/payment.service';
import { ScreenSizeService } from './modules/shared/services/screen-size.service';
import IUser, { UserService } from './modules/shared/services/user.service';
import { AlertService } from './services/alert.service';
import { AuthService } from './services/auth.service';
import { CmsService } from './services/cms.service';
import { App } from '@capacitor/app';
import { Network } from '@capacitor/network';
import { ToastService } from './services/toast.service';
import { SocialNetwork } from './services/content-model/social-network-model';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  splashSubscription: Subscription;

  userSubscription: Subscription;

  splash = false;
  user: IUser;
  url: string;
  constructor(
    private platform: Platform,
    private splashService: SplashService,
    private screenSizeService: ScreenSizeService,
    private authService: AuthService,
    private userService: UserService,
    private paymentService: PaymentService,
    private cmsService: CmsService,
    public route: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private toastService: ToastService
  ) {
    // Network.addListener('networkStatusChange', (status: any) => {
    //   if (!status.conected) {
    //     this.route.navigate(['/no-network']);
    //   } else {
    //     return null;
    //   }
    // });
    this.activatedRoute.queryParams.subscribe((params) => {
      const message = params['notify'];
      if (message) {
        this.alertService.alert(message);
      }
    });

    this.screenSizeService.onWindowResize(this.platform.width());

    this.authService.init();

    this.splashSubscription = this.splashService.subject.subscribe((value) => {
      this.splash = value;
    });

    this.userSubscription = this.userService.currentUser.subscribe((value) => {
      this.user = value;
    });

    if (Capacitor.getPlatform() !== 'web') {
      this.splashService.show();
      const timeOut = setTimeout(() => {
        this.splashService.hide().finally(() => {
          clearTimeout(timeOut);
        });
      }, 3000);
    }

    this.platform.ready().then(() => {
      setTimeout(() => {
        SplashScreen.hide({
          fadeOutDuration: 1000,
        });
      }, 3000);
    });

    App.addListener('backButton', (data) => {
      if (data.canGoBack) {
        window.history.back();
      } else {
        App.exitApp();
      }
    });

    this.cmsService.getPlanPrice().subscribe((res: any) => {
      let data = res?.data[0]?.attributes;
      let planPrices: PlanPrice = {
        plan1Monthly: data?.Plan1Monthly,
        plan1HalfYearly: data?.Plan1HalfYearly,
        plan1Yearly: data?.Plan1Yearly,
        plan2Monthly: data?.Plan2Monthly,
        plan2HalfYearly: data?.Plan2HalfYearly,
        plan2Yearly: data?.Plan2Yearly,
        plan3Monthly: data?.Plan3Monthly,
        plan3HalfYearly: data?.Plan3HalfYearly,
        plan3Yearly: data?.Plan3Yearly,
      };
      this.paymentService.setPlanPrice(planPrices);
    });

    this.cmsService.getSocialMediaUrl().subscribe((res: any) => {
      let data = res?.data[0]?.attributes;
      let url: SocialNetwork = {
        fb: data?.fb,
        instagram: data?.instagram,
        linkedin: data?.linkedin,
        twitter: data?.twitter,
        youtube: data?.youtube,
      };
      this.cmsService.setSocialMediaUrl(url);
    });
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.splash = false;
    this.splashSubscription?.unsubscribe();
    this.userSubscription?.unsubscribe();
  }
}
