import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { IonInput, ModalController, Platform } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';
import { LoadingService } from 'src/app/modules/shared/components/loading/loading.service';
import { ScreenSizeService } from 'src/app/modules/shared/services/screen-size.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { SignupComponent } from '../signup/signup.component';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @ViewChild('password')
  password: IonInput;

  userForm: FormGroup;
  successMsg = '';
  errorMsg = '';
  showPassword = false;
  error = {
    email: [
      {
        type: 'required',
        message: 'Provide email.',
      },
      {
        type: 'pattern',
        message: 'Email is not valid.',
      },
    ],
    password: [
      {
        type: 'required',
        message: 'Password is required.',
      },
      {
        type: 'minlength',
        message: 'Password length should be 6 characters long.',
      },
    ],
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private loadingService: LoadingService,
    private screenSizeService: ScreenSizeService,
    private modalController: ModalController,
    public platform: Platform,
    private http: HttpClient,
    private alertService: AlertService,
    public ngFireAuth: AngularFireAuth
  ) {}

  get isDesktop() {
    return this.screenSizeService.isDesktopView();
  }

  ngOnInit() {
    this.userForm = this.fb.group({
      email: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ])
      ),
      password: new FormControl('', Validators.compose([Validators.required])),
    });
  }

  async signIn(value: any) {
    try {
      const user = await this.authService.signIn(value.email, value.password);
      if (user.emailVerified == true) {
        if (user.role === 'admin') {
          this.router.navigate(['/admin']);
        } else if (user.role === 'user') {
          this.router.navigate(['/user']);
        }
        this.onClose();
      } else if (user.emailVerified == false) {
        this.alertService.alert('Please verify your email to login');
      }
    } catch (error) {
      this.alertService.alert('Invalid userid/password.');
    }
  }

  async goToSignup() {
    this.onClose();
    const signUpModal = await this.modalController.create({
      component: SignupComponent,
      cssClass: this.isDesktop ? 'modal-popup-login' : 'modal-popup-mobile',
    });
    await signUpModal.present();

    return false;
  }

  async goToForgetPassword() {
    this.onClose();
    const forgetPasswordModal = await this.modalController.create({
      component: ForgotPasswordComponent,
      cssClass: 'forgot-password',
    });
    return forgetPasswordModal.present();
    // return false;
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
    this.password.type = this.showPassword ? 'text' : 'password';
  }

  onClose() {
    this.modalController.dismiss({
      dismissed: true,
    });
    this.router.navigate(['/']);
  }
}
