import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { getAuth } from 'firebase/auth';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { LoginComponent } from 'src/app/components/login/login.component';
import { ToastService } from 'src/app/services/toast.service';
import { environment } from 'src/environments/environment';
import { PaymentService, PlanPrice } from '../../services/payment.service';
import { ScreenSizeService } from '../../services/screen-size.service';
import { UserService } from '../../services/user.service';
import { CheckoutComponent } from '../checkout/checkout.component';
import { Browser } from '@capacitor/browser';
import {
  IPayPalConfig,
  ICreateOrderRequest,
  NgxPaypalComponent,
  PayPalScriptService,
} from 'ngx-paypal';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss'],
})
export class PlansComponent implements AfterViewInit, OnInit {
  public payPalConfig?: IPayPalConfig;

  @Input()
  plan1: any = {};

  @Input()
  plan2: any = {};

  @Input()
  plan3: any = {};
  isModalOpen = false;
  planIds: {
    plan_monthly: string;
    plan_half_yearly: string;
    plan_yearly: string;
  };
  planId: string;
  data: {
    email: string;
    plan: string;
    type: string;
    createdBy: string;
    updatedBy: string;
  };
  desktop = true;
  subscription: Subscription;
  plan: string;
  planType: {
    plan: string;
    type: any;
    planId: string;
    planPrice: number;
    planName: string;
  };
  planMonthlyPrice: number;
  planHalfYearlyPrice: number;
  planYearlyPrice: number;
  currentPlanPrice: number;
  planName: string;
  planTypeOption: string;

  userSubscribed = false;
  email: string;
  selectedRadio: string;

  private plans = [];
  public configs = {};

  constructor(
    private userService: UserService,
    private alertService: AlertService,
    private alertController: AlertController,
    private modalController: ModalController,
    private screenSizeService: ScreenSizeService,
    private paymentService: PaymentService,
    private toastService: ToastService,
    private payPalScriptService: PayPalScriptService
  ) {
    this.selectedRadio = 'monthly';
  }

  ngAfterViewInit() {}

  planTypeForm = new FormGroup({
    type: new FormControl('', [Validators.required]),
  });

  planPriceDetails: PlanPrice;

  ngOnInit() {
    this.initNavConfig();
    this.planPriceDetails = this.paymentService.planPrices.getValue();
    const subscriptionStatus =
      this.userService.currentUser.getValue().userDetails?.isActive;
    if (subscriptionStatus) {
      this.userSubscribed = true;
    }
    this.email = this.userService.currentUser.getValue().email;
    this.initConfig('P-1A064486YJ7126831MPS4LRI');
  }

  getPlanTypeOption(value: string) {
    const planId = this.getPlanId(value);
    return this.initConfig(planId);
  }

  initConfig(planId: string) {
    const plan_id = planId;
    this.payPalConfig = {
      advanced: {
        extraQueryParams: [
          { name: 'disable-funding', value: 'venmo' },
          { name: 'enable-funding', value: 'credit,card' },
        ],
      },
      clientId: environment.PAYPAL_CLIENT_ID,
      currency: 'EUR',
      vault: 'true',
      style: {
        label: 'paypal',
        layout: 'vertical',
        shape: 'pill',
        color: 'blue',
        tagline: false,
        height: 40,
      },
      createSubscriptionOnClient: (data) => {
        return {
          plan_id: plan_id,
          quantity: 1,
          custom_id: '1',
        };
      },
      onApprove: async (data, actions) => {
        (
          await this.paymentService.createdPaypalSubscriptionCheckoutSession(
            data.subscriptionID,
            this.email
          )
        ).subscribe(
          (res: { message: string }) => {
            this.isModalOpen = false;
            this.alertService.alert(res?.message);
          },
          (err) => {
            this.alertService.alert(err);
          }
        );
      },
      onClientAuthorization: (data) => {
        this.alertService.alert(
          'onClientAuthorization - you should probably inform your server about completed transaction at this point' +
            data
        );
      },
      onCancel: (data, actions) => {
        this.alertService.alert('Cancelled');
        // this.showCancel = true;
      },
      onError: (err) => {
        if (err.capture) {
          this.alertService.alert('OnError' + err);
          //this.showError = true;
        }
      },
      onClick: (data, actions) => {
        //this.resetStatus();
      },
      fundingSource: 'CARD',
    };
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  async showChekout(planDetails: { plan: string; type: any }) {
    const checkoutModal = await this.modalController.create({
      component: CheckoutComponent,
      cssClass: 'checkout-page',
      componentProps: { planDetails: planDetails },
    });
    this.modalController.dismiss({
      dismissed: true,
    });
    return checkoutModal.present();
  }

  whenDismiss(isOpen) {
    this.isModalOpen = isOpen;
  }

  async onSubmit(planDetails) {
    const plan = await this.plan;
    if (this.planTypeForm.invalid) {
      for (const control of Object.keys(this.planTypeForm.controls)) {
        this.planTypeForm.controls[control].markAsTouched();
      }
      this.alertService.alert('Please enter mandatory fields!');
      return;
    } else {
      this.isModalOpen = false;
      (
        await this.paymentService.getSubscriptionCheckoutUrl(
          this.email,
          plan,
          planDetails.type
        )
      ).subscribe(
        async (response: any) => {
          const checkoutUrl = response?.url;
          const existMessage = response?.existMessage;
          if (existMessage) {
            await this.alertService.alert(existMessage).then((res) => {
              Browser.open({ url: checkoutUrl });
            });
          } else {
            Browser.open({ url: checkoutUrl });
          }
        },
        (error) => {
          this.alertService.alert(error);
        }
      );
    }
  }

  getPlanId(planType: string): string {
    if (planType == 'monthly') {
      this.currentPlanPrice = this.planMonthlyPrice;
      this.planId = this.planIds.plan_monthly;
    } else if (planType == 'halfyearly') {
      this.currentPlanPrice = this.planHalfYearlyPrice;
      this.planId = this.planIds.plan_half_yearly;
    } else if (planType == 'yearly') {
      this.currentPlanPrice = this.planYearlyPrice;
      this.planId = this.planIds.plan_yearly;
    }
    return this.planId;
  }

  setOpen(isOpen: boolean, plan: string) {
    if (this.userSubscribed) {
      this.alertService.alert('You already have a subscription plan.');
    } else {
      const user = this.userService.currentUser.getValue();
      const data = this.paymentService.planDetails.getValue();
      if (user.email) {
        this.plan = plan;
        if (this.plan == 'P1') {
          this.planName = data[0].planTitle;
          this.planMonthlyPrice = this.planPriceDetails.plan1Monthly;
          this.planHalfYearlyPrice = this.planPriceDetails.plan1HalfYearly;
          this.planYearlyPrice = this.planPriceDetails.plan1Yearly;
          this.planIds = {
            plan_monthly:
              environment.PAYPAL_PRODUCT_ID.P1_PLAN_ID.PAYPAL_P1_MONTHLY,
            plan_half_yearly:
              environment.PAYPAL_PRODUCT_ID.P1_PLAN_ID.PAYPAL_P1_HALF_YEARLY,
            plan_yearly:
              environment.PAYPAL_PRODUCT_ID.P1_PLAN_ID.PAYPAL_P1_YEARLY,
          };
        } else if (this.plan == 'P2') {
          this.planName = data[1].planTitle;
          this.planMonthlyPrice = this.planPriceDetails.plan2Monthly;
          this.planHalfYearlyPrice = this.planPriceDetails.plan2HalfYearly;
          this.planYearlyPrice = this.planPriceDetails.plan2Yearly;
          this.planIds = {
            plan_monthly:
              environment.PAYPAL_PRODUCT_ID.P2_PLAN_ID.PAYPAL_P2_MONTHLY,
            plan_half_yearly:
              environment.PAYPAL_PRODUCT_ID.P2_PLAN_ID.PAYPAL_P2_HALF_YEARLY,
            plan_yearly:
              environment.PAYPAL_PRODUCT_ID.P2_PLAN_ID.PAYPAL_P2_YEARLY,
          };
        } else if (this.plan == 'P3') {
          this.planName = data[2].planTitle;
          this.planMonthlyPrice = this.planPriceDetails.plan3Monthly;
          this.planHalfYearlyPrice = this.planPriceDetails.plan3HalfYearly;
          this.planYearlyPrice = this.planPriceDetails.plan3Yearly;
          this.planIds = {
            plan_monthly:
              environment.PAYPAL_PRODUCT_ID.P3_PLAN_ID.PAYPAL_P3_MONTHLY,
            plan_half_yearly:
              environment.PAYPAL_PRODUCT_ID.P3_PLAN_ID.PAYPAL_P3_HALF_YEARLY,
            plan_yearly:
              environment.PAYPAL_PRODUCT_ID.P3_PLAN_ID.PAYPAL_P3_YEARLY,
          };
        }
        this.isModalOpen = isOpen;
      } else {
        this.goToSigin();
        this.toastService.toast('Please login to subscribe!');
      }
    }
  }

  async goToSigin() {
    const loginModal = await this.modalController.create({
      component: LoginComponent,
      cssClass: this.desktop ? 'modal-popup-login' : 'modal-popup-mobile',
    });
    await loginModal.present();
  }

  private initNavConfig(): void {
    this.subscription = this.screenSizeService
      .isDesktopView()
      .subscribe((value) => {
        this.desktop = value;
      });
  }
}
