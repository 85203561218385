import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  IonInput,
  ModalController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { LoadingService } from 'src/app/modules/shared/components/loading/loading.service';
import { ScreenSizeService } from 'src/app/modules/shared/services/screen-size.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoginComponent } from '../login/login.component';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  password: IonInput;

  @ViewChild('confirmPassword')
  confirmPassword: IonInput;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private loadingService: LoadingService,
    private authService: AuthService,
    private screenSizeService: ScreenSizeService,
    private toastController: ToastController,
    private modalController: ModalController,
    public platform: Platform
  ) {}

  userForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
    ]),
  });

  get f() {
    return this.userForm.controls;
  }

  get isDesktop() {
    return this.screenSizeService.isDesktopView();
  }

  ngOnInit() {
    this.userForm = this.fb.group({
      email: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ])
      ),
    });
  }

  async reset(value) {
    this.authService.resetPassword(value.email).then(() => {
      this.onClose();
    });
  }

  async goToSigin() {
    this.onClose();
    const loginModal = await this.modalController.create({
      component: LoginComponent,
      cssClass: this.isDesktop ? 'modal-popup-login' : 'modal-popup-mobile',
    });
    await loginModal.present();

    return false;
  }

  onClose() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
