import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ScreenSizeService } from '../../services/screen-size.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent implements OnInit {
  ngUnsubscribe: Subject<any> = new Subject<any>();
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public ngFireAuth: AngularFireAuth,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
        const email = params['email'];
        const uid = params['uid'];
        if (!email && !uid) {
          this.router.navigate(['/home']);
        } else {
          this.userService.verifyEmail(email, uid).subscribe(
            (res) => {
              const message = res?.message;
              if (message == 'success') {
                this.router.navigate([`/home`], {
                  queryParams: { notify: 'Email verified successfully!' },
                });
              }
            },
            (error) => {
              this.router.navigate([`/home`], {
                queryParams: { notify: error },
              });
            }
          );
        }
      });
  }
}
