import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SocialNetwork } from './content-model/social-network-model';

@Injectable({
  providedIn: 'root',
})
export class CmsService {
  socialMediaUrl = new BehaviorSubject<SocialNetwork>(initialUrl);
  constructor(private http: HttpClient) {}

  setSocialMediaUrl(url: SocialNetwork) {
    this.socialMediaUrl.next(url);
  }

  getContentFromCMS(content: string) {
    return this.http.get(
      `${environment.cmsApi}/api/homepage?populate[` +
        content +
        `][populate]=*&token=false`
    );
  }

  news() {
    return this.http.get(
      `${environment.cmsApi}/api/new-and-events?populate=*&token=false`
    );
  }

  newsDetail(id: number) {
    return this.http.get(
      `${environment.cmsApi}/api/new-and-events/${id}?populate=*&token=false`
    );
  }

  homeContent() {
    return this.http.get<any>(`${environment.cmsApi}/api/homepage?token=false`);
  }

  irishLotto() {
    return this.http.get(`${environment.cmsApi}/api/irish-lottos?token=false`);
  }

  euroMillions() {
    return this.http.get(`${environment.cmsApi}/api/euro-millions?token=false`);
  }

  getCounty() {
    return this.http.get<any>('assets/county.json?token=false');
  }

  getPlanPrice() {
    return this.http.get(`${environment.cmsApi}/api/plan-prices?token=false`);
  }

  getSocialMediaUrl() {
    return this.http.get(
      `${environment.cmsApi}/api/social-networks?token=false`
    );
  }

  getDisclaimerContent(page: string) {
    return this.http.get(`${environment.cmsApi}/api/${page}/1?token=false`);
  }
}

export const initialUrl: SocialNetwork = {
  fb: null,
  instagram: null,
  linkedin: null,
  twitter: null,
  youtube: null,
};
