import { Inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FirebaseApp } from '@angular/fire/app';
import IUser, {
  UserService,
  initialUserData,
} from '../modules/shared/services/user.service';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import jwtDecode from 'jwt-decode';
import { NavigationEnd, Router } from '@angular/router';
import { Register } from './user-model/register-model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ToastController } from '@ionic/angular';
import { ToastService } from './toast.service';
import { IUserDetail } from './user-model/user-model';
import { map } from '@firebase/util';
import { BehaviorSubject } from 'rxjs';
import {
  EmailAuthProvider,
  getAuth,
  onAuthStateChanged,
  reauthenticateWithCredential,
  updatePassword,
} from 'firebase/auth';
import { Capacitor } from '@capacitor/core';
import { NotificationsService } from '../modules/shared/services/notification.service';
import { AlertService } from './alert.service';
import { Network } from '@capacitor/network';
import { last } from 'rxjs/operators';

@Injectable()
export class AuthService {
  private idToken: string | null = null;
  private refreshToken: string | null = null;
  status: boolean;
  constructor(
    public ngFireAuth: AngularFireAuth,
    private userService: UserService,
    private router: Router,
    private http: HttpClient,
    private alertService: AlertService,
    private toastService: ToastService,
    public pushNotification: NotificationsService
  ) {}

  async signIn(username: string, password: string) {
    const credential = await this.ngFireAuth.signInWithEmailAndPassword(
      username,
      password
    );
    return this.createUser(credential.user, await credential.user.getIdToken());
  }

  showWelcomeMessage() {
    this.alertService.alert(
      'Welcome to mysyndicates.IE , please complete your profile '
    );
  }

  getAuth() {
    return getAuth();
  }

  getIdToken(): string {
    return this.idToken;
  }

  getRefreshToken(): string {
    return this.refreshToken;
  }

  async signOut() {
    return this.ngFireAuth.signOut();
  }

  async signUp(username: string, password: string) {
    const user = (
      await this.ngFireAuth.createUserWithEmailAndPassword(username, password)
    ).user;
    // const actionCodeSettings = {
    //   url: `https://localhost:4200/verify-email?${user.uid}`,
    //   iOS: {
    //     bundleId: 'com.lottomax.mysyndicate',
    //   },
    //   android: {
    //     packageName: 'com.lottomax.mysyndicate',
    //     installApp: true,
    //     minimumVersion: '12',
    //   },
    //   handleCodeInApp: true,
    // };
    // return user.sendEmailVerification(actionCodeSettings);
    return user.sendEmailVerification();
  }

  async register(user: Register) {
    let userData = {
      email: user.email,
      password: user.password,
      internalUser: user.internalUser,
    };
    return this.http
      .post<any>(`${environment.baseApiUrl}/users`, userData)
      .subscribe(
        (response) => {
          this.alertService.alert(
            'Thank you for registering with mysyndicates.ie. Please confirm your registration by verifying your email.'
          );
        },
        (error) => {
          this.alertService.alert(error);
        }
      );
  }

  handleResetPassword(actionCode: string, newPassword: string) {
    this.ngFireAuth
      .confirmPasswordReset(actionCode, newPassword)
      .then((resp) => {
        this.alertService.alert('Password changed successfully.');
        this.router.navigate(['/home']);
      })
      .catch((e) => {
        this.alertService.alert(e);
      });
  }

  updateIsNewStatus(email: string) {
    return this.http
      .get<any>(
        `${environment.baseApiUrl}/users/user/status/change/isnew/by/email/${email}`
      )
      .subscribe(
        (response) => {},
        (error) => {
          this.alertService.alert(error);
        }
      );
  }

  async resetPassword(email: string) {
    return this.ngFireAuth
      .sendPasswordResetEmail(email)
      .then((res) => {
        this.alertService.alert(
          'A password reset link has been sent to your email address'
        );
      })
      .catch((error) => {
        if (error.code == 'auth/user-not-found') {
          this.alertService.alert(
            'There is no user corresponding to this email address. The user may have been deleted.'
          );
        } else {
          this.alertService.alert(error.message);
        }
      });
  }

  async changePassword(data: { oldPassword: string; newPassword: string }) {
    const cpUser = await getAuth().currentUser;
    const credentials = await EmailAuthProvider.credential(
      cpUser.email,
      data.oldPassword
    );
    reauthenticateWithCredential(cpUser, credentials).then(
      (success) => {
        updatePassword(cpUser, data.newPassword)
          .then((e) => {
            this.alertService.alert('Password changed successfully!');
          })
          .catch((error) => {
            this.alertService.alert(error);
          });
      },
      (error) => {
        if (error.code === 'auth/wrong-password') {
          this.alertService.alert('Please enter correct password');
        }
      }
    );
  }

  async checkUserWithPassword(password: string) {
    const cpUser = await getAuth().currentUser;
    const credentials = await EmailAuthProvider.credential(
      cpUser.email,
      password
    );
    return await reauthenticateWithCredential(cpUser, credentials);
  }

  async deleteUser(email: string, uid: string, adminEmail: string) {
    return await this.http.delete<any>(
      `${environment.baseApiUrl}/users/${email}/${uid}/${adminEmail}/delete/user/details`
    );
  }

  async refreshUser() {
    const cpUser = await getAuth().currentUser;
    const token = await cpUser.getIdToken();
    let currentUser = this.createUser(cpUser, token);
    this.userService.setCurrentUser(currentUser);
    let data: any = await this.userService.findUserDetails(cpUser.email);
    if (!data) {
      await this.alertService.alert(
        'There is no user corresponding to this email address. The user may have been deleted.'
      );
      await this.signOut().then(async () => {
        await this.router.navigate(['/home']);
      });
    }
    let documents =
      data?.documents?.map((doc: any) => {
        return {
          id: doc?.id,
          email: doc?.email,
          name: doc?.name,
          size: doc?.size,
          file: doc?.file,
        };
      }) || [];

    let userDetails: IUserDetail = {
      uid: data?.uid,
      firstname: data?.firstName,
      lastname: data?.lastName,
      dob: data?.dob,
      email: data?.email,
      number: data?.phoneNumber,
      address1: data?.address?.addressLine1,
      address2: data?.address?.addressLine2,
      city: data?.address?.city,
      county: data?.address?.county,
      eircode: data?.address?.eirCode,
      joindate: data?.createdAt,
      isActive: data?.isActive,
      kycStatus: data?.kycStatus,
      profilePhoto: data?.profilePhotoUrl,
      phoneNumberVerified: data?.phoneNumberVerified,
      isNew: data?.isNew,
      subscription: {
        plan: data?.subscription?.plan,
        type: data?.subscription?.type,
        startDate: data?.subscription?.startDate,
        endDate: data?.subscription?.endDate,
        isActive: data?.subscription?.isActive,
        gateway: data?.subscription?.gateway,
      },
      documents: [...documents],
    };
    currentUser.userDetails = userDetails;
    this.userService.setCurrentUser(currentUser);
  }

  public init() {
    this.ngFireAuth.user.subscribe(async (user) => {
      if (user && !user.isAnonymous) {
        const token = await user.getIdToken();
        if (user.emailVerified == true) {
          let currentUser = this.createUser(user, token);
          this.idToken = token;
          this.refreshToken = user.refreshToken;
          this.userService.setCurrentUser(currentUser);
          let data: any = await this.userService.findUserDetails(user.email);
          if (!data) {
            this.alertService.alert(
              'There is no user corresponding to this email address. The user may have been deleted.'
            );
            this.signOut().then(() => {
              this.router.navigate(['/home']);
            });
          }
          let documents =
            data?.documents?.map((doc: any) => {
              return {
                id: doc?.id,
                email: doc?.email,
                name: doc?.name,
                size: doc?.size,
                file: doc?.file,
              };
            }) || [];

          let userDetails: IUserDetail = {
            uid: data?.uid,
            firstname: data?.firstName,
            lastname: data?.lastName,
            dob: data?.dob,
            email: data?.email,
            number: data?.phoneNumber,
            address1: data?.address?.addressLine1,
            address2: data?.address?.addressLine2,
            city: data?.address?.city,
            county: data?.address?.county,
            eircode: data?.address?.eirCode,
            joindate: data?.createdAt,
            isActive: data?.isActive,
            kycStatus: data?.kycStatus,
            profilePhoto: data?.profilePhotoUrl,
            phoneNumberVerified: data?.phoneNumberVerified,
            isNew: data?.isNew,
            subscription: {
              plan: data?.subscription?.plan,
              type: data?.subscription?.type,
              startDate: data?.subscription?.startDate,
              endDate: data?.subscription?.endDate,
              isActive: data?.subscription?.isActive,
              gateway: data?.subscription?.gateway,
            },
            documents: [...documents],
          };
          if (userDetails.isNew) {
            this.showWelcomeMessage();
            this.updateIsNewStatus(user.email);
          }
          currentUser.userDetails = userDetails;
          this.userService.setCurrentUser(currentUser);
          this.redirect(currentUser);
          if (Capacitor.getPlatform() !== 'web') {
            this.pushNotification.initPush(userDetails.email);
          }
        } else {
          this.signOut();
        }
      } else {
        this.userService.setCurrentUser(initialUserData);
      }
    });
  }

  private createUser(user: any, token: string): IUser {
    return {
      id: user.uid,
      displayName: user.displayName,
      email: user.email,
      photoUrl: user.photoURL,
      isAuthenticated: true,
      emailVerified: user.emailVerified,
      role: (jwtDecode(token) as any)?.role || 'user' || 'admin',
    };
  }

  private redirect(currentUser: IUser) {
    if (currentUser.role === 'user') {
      this.router.navigate(['/user']);
    } else if (currentUser.role === 'admin') {
      this.router.navigate(['/admin']);
    }
  }

  async getNetworkStatus(): Promise<boolean> {
    const status = await Network.getStatus();
    this.status = status?.connected;
    if (!this.status) {
      return false;
    } else {
      return true;
    }
  }
}
