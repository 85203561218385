import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-profile-photo',
  templateUrl: './profile-photo.component.html',
  styleUrls: ['./profile-photo.component.scss'],
})
export class ProfilePhotoComponent implements OnInit {
  photo: File;
  userEmail: string;
  imageUrl: string;
  imageInDb: string;
  constructor(
    private modalController: ModalController,
    private userService: UserService,
    private toastService: ToastService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.getUser();
  }

  async getUser() {
    this.userEmail = await this.userService.currentUser.getValue().email;
    this.imageInDb = await this.userService.currentUser.getValue().userDetails
      .profilePhoto;
  }

  profilePhotoForm = new FormGroup({
    photo: new FormControl('', [Validators.required]),
  });

  handleFileInput(file: FileList) {
    this.photo = file.item(0);
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    };
    reader.readAsDataURL(this.photo);
  }

  uploadPhoto() {
    this.onClose();
    if (this.photo) {
      this.userService
        .uploadUserProfilePicture(this.userEmail, this.photo)
        .subscribe((resp) => {
          this.authService.refreshUser().then((res) => {
            this.getUser();
          });
          this.toastService.toast('Profile picture successfully uploaded!');
        });
    } else {
      this.toastService.toast('Please select a file first!');
    }
  }

  removeProfilePhoto() {
    this.userService.removeProfilePhoto(this.userEmail).subscribe(
      (res) => {
        this.onClose();
        this.authService.refreshUser().then((res) => {
          this.getUser();
        });
        this.toastService.toast('Profile picture successfully deleted!');
      },
      (error) => {
        this.toastService.toast(error);
      }
    );
  }

  onClose() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
