import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { disclaimerUrl } from 'src/app/constants/disclaimer-url';
import { CmsService } from 'src/app/services/cms.service';
import { SocialNetwork } from 'src/app/services/content-model/social-network-model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  footerMenu = [
    { title: 'Home', id: '#home' },
    { title: 'How it works', id: '#how' },
    { title: 'Our plans', id: '#plans' },
    { title: 'Results', id: '#result' },
    { title: 'News & Events', id: '#news' },
  ];

  privacyPolicyUrl = disclaimerUrl.privacyPolicy;
  termsAndConditionsUrl = disclaimerUrl.termsAndConditions;
  userAgreementUrl = disclaimerUrl.userAgreement;
  faqUrl = disclaimerUrl.faq;
  socialUrl: SocialNetwork;
  constructor(private contentApi: CmsService) {}

  ngOnInit() {
    this.contentApi.socialMediaUrl.subscribe((res) => {
      this.socialUrl = res;
    });
  }

  onFooterClick(id: string) {
    document
      .querySelector(id)
      .scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
