import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert.service';
import { ToastService } from 'src/app/services/toast.service';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { createHmac } from 'create-hmac';
import { randomBytes } from 'randombytes';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  planType: {
    plan: string;
    type: string;
  };
  planId: string;

  planPrices = new BehaviorSubject<PlanPrice>(initialPlansData);
  planDetails = new BehaviorSubject<PlanModel[]>(initialPlan);

  setPlanPrice(planPrice: PlanPrice) {
    this.planPrices.next(planPrice);
  }

  setPlanDetails(plan) {
    let planFeature1 = [];
    plan[0]?.data?.attributes?.plan1?.plan_feature?.forEach((element) => {
      planFeature1.push(element.plan_feature);
    });
    let plan1Details: PlanModel = {
      planTitle: plan[0]?.data?.attributes?.plan1?.plan_title,
      planPrice: plan[0]?.data?.attributes?.plan1?.plan_price,
      planFeature: planFeature1,
    };
    let planFeature2 = [];
    plan[1]?.data?.attributes?.plan2?.plan_feature?.forEach((element) => {
      planFeature2.push(element.plan_feature);
    });
    let plan2Details: PlanModel = {
      planTitle: plan[1]?.data?.attributes?.plan2?.plan_title,
      planPrice: plan[1]?.data?.attributes?.plan2?.plan_price,
      planFeature: planFeature2,
    };
    let planFeature3 = [];
    plan[2]?.data?.attributes?.plan3?.plan_feature?.forEach((element) => {
      planFeature3.push(element.plan_feature);
    });
    let plan3Details: PlanModel = {
      planTitle: plan[2]?.data?.attributes?.plan3?.plan_title,
      planPrice: plan[2]?.data?.attributes?.plan3?.plan_price,
      planFeature: planFeature3,
    };
    let plansDetails = [plan1Details, plan2Details, plan3Details];
    this.planDetails.next(plansDetails);
  }

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private alertService: AlertService
  ) {}

  getPlanDetails() {
    return this.planType;
  }

  setplanDetails(planDetails) {
    this.planType = {
      plan: 'standard',
      type: planDetails.type,
    };
  }

  createCustomer(customerDetails, token: string, name: string) {
    const paymentData = {
      email: customerDetails.email,
      plan: customerDetails.plan,
      type: customerDetails.type,
      createdBy: customerDetails.email,
      updatedBy: customerDetails.email,
      planId: customerDetails.planId,
    };
    return this.http.post(
      `${environment.baseApiUrl}` +
        `/payments/subscription-checkout-session/` +
        customerDetails.email +
        `/` +
        token +
        '/' +
        name,
      paymentData
    );
  }

  async cancelSubscription(email: string, uid: string) {
    return this.http
      .get(
        `${environment.baseApiUrl}` +
          `/payments/subscription-cancel/` +
          email +
          `/` +
          uid
      )
      .subscribe(
        (response) => {
          this.alertService.alert('Unsubscribe request sent!');
        },
        (error) => {
          this.alertService.alert(error);
        }
      );
  }

  async getCustomerId(email: string, name: string) {
    return await this.http
      .get(
        `${environment.baseApiUrl}/users/user/customer/id/from/database/customer/id/customer/${email}`
      )
      .subscribe(
        (response: { id: string }) => {
          const id = response?.id;
          if (!id) {
            // this.createCustomerRapyd(email, name);
          } else {
            return response;
          }
        },
        (error) => {}
      );
  }

  async getSubscriptionCheckoutUrl(
    email: string,
    plan: string,
    planType: string
  ) {
    return await this.http.get(
      `${environment.baseApiUrl}/payments/subscription/checkout/user/session/url/${email}/${plan}/${planType}`
    );
  }

  async createdPaypalSubscriptionCheckoutSession(id: string, email: string) {
    return await this.http.get(
      `${environment.baseApiUrl}/payments/subscription/checkout/user/details/${id}/${email}`
    );
  }
}

export const initialPlansData: PlanPrice = {
  plan1Monthly: 0,
  plan1HalfYearly: 0,
  plan1Yearly: 0,
  plan2Monthly: 0,
  plan2HalfYearly: 0,
  plan2Yearly: 0,
  plan3Monthly: 0,
  plan3HalfYearly: 0,
  plan3Yearly: 0,
};

export interface PlanPrice {
  plan1Monthly: number;
  plan1HalfYearly: number;
  plan1Yearly: number;
  plan2Monthly: number;
  plan2HalfYearly: number;
  plan2Yearly: number;
  plan3Monthly: number;
  plan3HalfYearly: number;
  plan3Yearly: number;
}

export const initialPlan: PlanModel[] = [
  {
    planTitle: null,
    planFeature: null,
    planPrice: 0,
  },
];

export interface PlanModel {
  planTitle: string;
  planFeature: PlanFeature[];
  planPrice: number;
}

export interface PlanFeature {
  planFeature: string;
}
