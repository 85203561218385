import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ticket-number',
  templateUrl: './ticket-number.component.html',
  styleUrls: ['./ticket-number.component.scss'],
})
export class TicketNumberComponent implements OnInit {

  @Input()
  ticketNos : {
    number : string;
  }

  @Input()
  raffleNos : {
    number : string;
  }



  constructor() { }

  ngOnInit() {}

}
