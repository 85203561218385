import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IonInput, ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert.service';
import { ScreenSizeService } from 'src/app/modules/shared/services/screen-size.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  @ViewChild('newPassword')
  newPassword: IonInput;

  @ViewChild('confirmNewPassword')
  confirmNewPassword: IonInput;

  showNewPassword = false;
  showConfirmNewPassword = false;

  ngUnsubscribe: Subject<any> = new Subject<any>();
  // The user management actoin to be completed
  mode: string;
  // Just a code Firebase uses to prove that
  // this is a real password reset.
  actionCode: string;

  actionCodeChecked: boolean;

  constructor(
    private screenSizeService: ScreenSizeService,
    private modalController: ModalController,
    private authService: AuthService,
    private alertService: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public ngFireAuth: AngularFireAuth
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
        // if we didn't receive any parameters,
        // we can't do anything
        // if (!params) {
        //   this.router.navigate(['/home']);
        // }

        this.mode = params['mode'];
        this.actionCode = params['oobCode'];

        switch (params['mode']) {
          case 'resetPassword':
            {
              this.ngFireAuth
                .verifyPasswordResetCode(this.actionCode)
                .then((email) => {
                  this.actionCodeChecked = true;
                })
                .catch((e) => {
                  this.router.navigate(['/home']);
                });
            }
            break;
          case 'recoverEmail':
            {
            }
            break;
          case 'verifyEmail':
            {
            }
            break;
          default: {
            this.router.navigate(['/home']);
          }
        }
      });
  }

  userForm = new FormGroup({
    newPassword: new FormControl('', [
      Validators.required,
      Validators.pattern(
        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
      ),
    ]),
    confirmNewPassword: new FormControl('', [
      Validators.required,
      Validators.pattern(
        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
      ),
    ]),
  });

  get f() {
    return this.userForm.controls;
  }

  toggleNewPassword() {
    this.showNewPassword = !this.showNewPassword;
    this.newPassword.type = this.showNewPassword ? 'text' : 'password';
  }

  toggleConfirmNewPassword() {
    this.showConfirmNewPassword = !this.showConfirmNewPassword;
    this.confirmNewPassword.type = this.showConfirmNewPassword
      ? 'text'
      : 'password';
  }

  get isDesktop() {
    return this.screenSizeService.isDesktopView();
  }

  changePassword(value) {
    if (this.userForm.invalid) {
      for (const control of Object.keys(this.userForm.controls)) {
        this.userForm.controls[control].markAsTouched();
      }
      this.alertService.alert('Please enter mandatory fields!');
      return;
    } else {
      let password = this.userForm.controls.newPassword.value;
      let confirmPassword = this.userForm.controls.confirmNewPassword.value;
      if (password != confirmPassword) {
        this.alertService.alert(`Confirm password doesn't match New password`);
      } else {
        this.authService.handleResetPassword(
          this.actionCode,
          value.newPassword
        );
      }
    }
  }

  onClose() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
