import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IonInput, ModalController } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { ScreenSizeService } from '../../services/screen-size.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  @ViewChild('password')
  password: IonInput;

  @ViewChild('newPassword')
  newPassword: IonInput;

  @ViewChild('confirmNewPassword')
  confirmNewPassword: IonInput;

  showNewPassword = false;
  showPassword = false;
  showConfirmNewPassword = false;

  constructor(
    private screenSizeService: ScreenSizeService,
    private modalController: ModalController,
    private authService: AuthService,
    private alertService: AlertService
  ) {}

  ngOnInit() {}

  userForm = new FormGroup({
    oldPassword: new FormControl('', [Validators.required]),
    newPassword: new FormControl('', [
      Validators.required,
      Validators.pattern(
        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
      ),
    ]),
    confirmNewPassword: new FormControl('', [
      Validators.required,
      Validators.pattern(
        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
      ),
    ]),
  });

  get f() {
    return this.userForm.controls;
  }
  togglePassword() {
    this.showPassword = !this.showPassword;
    this.password.type = this.showPassword ? 'text' : 'password';
  }

  toggleNewPassword() {
    this.showNewPassword = !this.showNewPassword;
    this.newPassword.type = this.showNewPassword ? 'text' : 'password';
  }

  toggleConfirmNewPassword() {
    this.showConfirmNewPassword = !this.showConfirmNewPassword;
    this.confirmNewPassword.type = this.showConfirmNewPassword
      ? 'text'
      : 'password';
  }

  get isDesktop() {
    return this.screenSizeService.isDesktopView();
  }

  changePassword(value) {
    let password = this.userForm.controls.newPassword.value;
    let confirmPassword = this.userForm.controls.confirmNewPassword.value;
    if (password != confirmPassword) {
      this.alertService.alert(`Confirm password doesn't match New password`);
    } else {
      this.modalController.dismiss({
        dismissed: true,
      });
      let data = {
        oldPassword: value.oldPassword,
        newPassword: value.newPassword,
      };
      this.authService.changePassword(data);
    }
  }

  onClose() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
