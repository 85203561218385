import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-view-ticket',
  templateUrl: './view-ticket.component.html',
  styleUrls: ['./view-ticket.component.scss'],
})
export class ViewTicketComponent implements OnInit {
  @Input()
  ticketUrl;

  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  onClose() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
