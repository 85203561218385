import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { ScreenSizeService } from './services/screen-size.service';
import { HeaderComponent } from './components/header/header.component';
import { NavComponent } from './components/nav/nav.component';
import { LoadingComponent } from './components/loading/loading.component';
import { LoadingService } from './components/loading/loading.service';
import { UserService } from './services/user.service';
import { FooterComponent } from './components/footer/footer.component';
import { PlansComponent } from './components/plans/plans.component';
import { NewsComponent } from './components/news/news.component';
import { ResultComponent } from './components/result/result.component';
import { ResultCardComponent } from './components/result-card/result-card.component';
import { NumberComponent } from './components/number/number.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OtpComponent } from './components/otp/otp.component';
import { WalletCardComponent } from './components/wallet-card/wallet-card.component';
import { TableWrapperComponent } from './components/table-wrapper/table-wrapper.component';
import { AdminSideNavComponent } from './components/admin-side-nav/admin-side-nav.component';
import { ProgressComponent } from './components/progress/progress.component';
import { MaterialExampleModule } from './material.module';
import { FilterModalComponent } from './components/filter-modal/filter-modal.component';
import { PayoutFormComponent } from './components/payout-form/payout-form.component';
import { BadgeComponent } from './components/badge/badge.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { PaymentService } from './services/payment.service';
import { SubscriptionPlanComponent } from './components/subscription-plan/subscription-plan.component';
import { NotificationComponent } from './components/notification/notification.component';
import { SyndicateService } from './services/syndicate.service';
import { TicketNumberComponent } from './components/ticket-number/ticket-number.component';
import { TicketComponent } from './components/ticket/ticket.component';
import { ProfilePhotoComponent } from './components/profile-photo/profile-photo.component';
import { TicketService } from './services/ticket.service';
import { TransactionService } from './services/transaction.service';
import { WalletService } from './services/wallet.service';
import { PayoutService } from './services/payout.service';
import { DocumentComponent } from './components/document/document.component';
import { NotificationsService } from './services/notification.service';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { PayoutAdminFormComponent } from './components/payout-admin-form/payout-admin-form.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { InAppBrowser } from '@ionic-native/in-app-browser';
import { NgxPayPalModule } from 'ngx-paypal';
import { LottieModule } from 'ngx-lottie';
import { ViewTicketComponent } from './components/view-ticket/view-ticket.component';

@NgModule({
  declarations: [
    HeaderComponent,
    NavComponent,
    LoadingComponent,
    PlansComponent,
    NewsComponent,
    ResultComponent,
    FooterComponent,
    ResultCardComponent,
    NumberComponent,
    SideNavComponent,
    OtpComponent,
    WalletCardComponent,
    TableWrapperComponent,
    AdminSideNavComponent,
    ProgressComponent,
    FilterModalComponent,
    PayoutFormComponent,
    BadgeComponent,
    CheckoutComponent,
    SubscriptionPlanComponent,
    NotificationComponent,
    TicketNumberComponent,
    TicketComponent,
    ProfilePhotoComponent,
    DocumentComponent,
    ChangePasswordComponent,
    PayoutAdminFormComponent,
    VerifyEmailComponent,
    ViewTicketComponent,
  ],
  providers: [LoadingService, InAppBrowser],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MaterialExampleModule,
    FormsModule,
    NgxPayPalModule,
    LottieModule,
  ],
  exports: [
    ReactiveFormsModule,
    FlexLayoutModule,
    HeaderComponent,
    NavComponent,
    LoadingComponent,
    PlansComponent,
    NewsComponent,
    ResultComponent,
    FooterComponent,
    ResultCardComponent,
    NumberComponent,
    SideNavComponent,
    OtpComponent,
    WalletCardComponent,
    TableWrapperComponent,
    AdminSideNavComponent,
    ProgressComponent,
    FilterModalComponent,
    MaterialExampleModule,
    PayoutFormComponent,
    BadgeComponent,
    CheckoutComponent,
    FormsModule,
    SubscriptionPlanComponent,
    NotificationComponent,
    TicketNumberComponent,
    TicketComponent,
    ProfilePhotoComponent,
    DocumentComponent,
    ChangePasswordComponent,
    PayoutAdminFormComponent,
    VerifyEmailComponent,
    ViewTicketComponent,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule,
      providers: [
        UserService,
        PaymentService,
        SyndicateService,
        TicketService,
        TransactionService,
        WalletService,
        PayoutService,
        ScreenSizeService,
        NotificationsService,
      ],
    };
  }
}
