import { Component, Input, OnInit, Pipe } from '@angular/core';
import { stringToKeyValue } from '@angular/flex-layout/extended/style/style-transforms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
})
export class DocumentComponent implements OnInit {
  @Input()
  data: any;

  loading = true;

  urlSafe: SafeResourceUrl;
  documentUrl: string;
  constructor(
    private modalController: ModalController,
    private userService: UserService,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.getDocumentUrl(this.data.email, this.data.id);
    setTimeout(() => {
      this.loading = false;
    }, 3000);
  }

  getDocumentUrl(email: string, id: number) {
    this.userService.getUserDocumentUrl(email, id).subscribe(
      (res: any) => {
        this.documentUrl = res?.documentUrl;
      },
      (error) => {}
    );
  }

  openURL() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.documentUrl);
  }

  onClose() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
