import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { INavConfig } from 'src/app/modules/shared/components/nav/nav.component';
import { ScreenSizeService } from 'src/app/modules/shared/services/screen-size.service';
import userNavJson from '../../../config/user.menu.json';

@Component({
  selector: 'app-user-layout',
  templateUrl: './user-layout.component.html',
  styleUrls: ['./user-layout.component.scss'],
})
export class UserLayoutComponent implements OnInit, OnDestroy {
  navConfig: INavConfig;
  subscription: Subscription;
  desktop = false;
  constructor(private screenSizeService: ScreenSizeService,) { }

  get isDesktop() {
    return this.screenSizeService.isDesktopView();
  }

  ngOnInit() {
    this.initNavConfig();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private initNavConfig(): void {
    this.navConfig = userNavJson as INavConfig;
    this.subscription = this.screenSizeService
      .isDesktopView()
      .subscribe((value) => {
        this.desktop = value;
      });
  }
}
