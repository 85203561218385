import { Component, Input, NgZone, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TicketService } from '../../services/ticket.service';
import { Ticket } from 'src/app/services/content-model/ticket-model/ticket-model';
import { Subscription } from 'rxjs';
import { ToastService } from 'src/app/services/toast.service';
import { AnimationOptions } from 'ngx-lottie';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ViewTicketComponent } from '../view-ticket/view-ticket.component';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
})
export class TicketComponent implements OnInit {
  options: AnimationOptions = {
    path: '/assets/animations/no-data.json',
  };

  @Input()
  syndicateId: string;

  @Input()
  lotteryType: string;

  isTicket = false;

  fileUrl: { signedUrl: string; expirationTime: string };
  ticketNumbers: Ticket[];
  ticket: Subscription;
  raffleNumber: any = [];
  raffle: any;
  numbers: any;

  constructor(
    private ticketService: TicketService,
    private modalController: ModalController,
    private toastService: ToastService,
    private sanitizer: DomSanitizer,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    if (this.lotteryType == 'lottery') {
      this.getTicket(this.syndicateId);
    } else if (this.lotteryType == 'dailyMillion') {
      this.getDailyMillionTicket(this.syndicateId);
    }
    setTimeout(() => {
      this.isTicket = true;
    }, 5000);
  }

  getTicket(syndicateId: string) {
    this.ticketService.getTicket(syndicateId).subscribe(
      (response) => {
        if (response == null) {
          return this.toastService.toast(`Ticket doesn't exist!`);
        }
        this.ticketNumbers =
          response?.map((res: any) => {
            if (res.ticketName != 'dailyMillion') {
              return {
                ticketNumbers: res?.ticketNumber,
                raffleNumber: res?.raffleNumber,
              };
            }
          }) || null;
        this.raffleNumber =
          response?.map((res: any) => {
            if (res.ticketName != 'dailyMillion') {
              return {
                raffleNumber: res?.raffleNumber,
              };
            }
          }) || null;
        this.numbers = this.raffle?.raffleNumber;
        this.viewTicket(response[0]?.groupId);
      },
      (err) => {
        this.toastService.toast('No ticket available');
      }
    );
  }

  viewTicket(groupId: string) {
    this.ticket = this.ticketService.getTicketUrl(groupId).subscribe(
      (url) => {
        this.ngZone.run(() => {
          this.fileUrl = {
            expirationTime: url?.expirationTime,
            signedUrl: url?.signedUrl,
          };
          this.viewTicketUImage(this.fileUrl.signedUrl);
        });
      },
      (error) => {
        this.toastService.toast('No ticket available');
      }
    );
  }

  getDailyMillionTicket(syndicateId) {
    this.ticketService.getTicket(syndicateId).subscribe((response) => {
      this.ticketNumbers =
        response?.map((res: any) => {
          if (res.ticketName == 'dailyMillion') {
            return {
              ticketNumbers: res?.ticketNumber,
              raffleNumber: res?.raffleNumber,
            };
          }
        }) || null;
      this.raffleNumber =
        response?.map((res: any) => {
          if (res.ticketName == 'dailyMillion') {
            return {
              raffleNumber: res?.raffleNumber,
            };
          }
        }) || null;
      const numbers = this.raffleNumber.map((res) => {
        if (res) {
          this.raffle = res;
        }
      });
      this.numbers = this.raffle?.raffleNumber;

      this.viewDailyMillionTicket(response[0]?.groupId);
    });
  }

  viewDailyMillionTicket(file: string) {
    this.ticketService.getDailyMillionTicket(file).subscribe(
      (url) => {
        this.fileUrl = {
          expirationTime: url?.expirationTime,
          signedUrl: url?.signedUrl,
        };
        this.viewTicketUImage(this.fileUrl.signedUrl);
      },
      (error) => {
        this.toastService.toast('No ticket available');
      }
    );
  }

  async viewTicketUImage(url: string) {
    this.modalController.dismiss({
      dismissed: true,
    });
    const ticketModal = await this.modalController.create({
      component: ViewTicketComponent,
      cssClass: 'ticket-page',
      componentProps: { ticketUrl: url },
    });
    return ticketModal.present();
  }

  getSafeUrl(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  onClose() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  ngOnDestroy(): void {
    this.syndicateId = null;
  }
}
