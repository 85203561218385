import { Component, Input, OnInit } from '@angular/core';
import secondaryNavItems from 'src/app/config/user.menu.json';
import { ToastService } from 'src/app/services/toast.service';
import { NotificationsService } from '../../services/notification.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  @Input()
  option: string;
  menu: any = [];
  notificationCount: number;
  userEmail: string;
  constructor(
    private notificationService: NotificationsService,
    private toastService: ToastService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.menu = secondaryNavItems.secondaryNavItems;
    this.userEmail = this.userService.currentUser.getValue().email;
    this.getNotificationCount();
    this.getNotificationCountByEmail(this.userEmail);
  }

  getNotificationCount() {
    this.notificationService.notificationCount.subscribe((res) => {
      const count = res;
      this.notificationCount = res;
      if (count == 0) {
        this.notificationCount = null;
      } else {
        this.notificationCount = count;
      }
    });
  }

  getNotificationCountByEmail(email: string) {
    this.notificationService.getNotificationCountByEmail(email).subscribe(
      (res: number) => {
        const count = res;
        if (count != 0) {
          this.notificationCount = res;
        }
      },
      (error) => {
        this.toastService.toast(error);
      }
    );
  }
}
