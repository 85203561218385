import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SyndicateService {
  constructor(private http: HttpClient) {}

  getSyndicates(page: number) {
    const url = `${environment.baseApiUrl}/group/current/` + page;
    return url;
  }

  getSyndicateHistory(page: number) {
    const url = `${environment.baseApiUrl}/group/backup/` + page;
    return url;
  }

  getSyndicateHistoryByEmail(email: string, page: number) {
    const url = `${environment.baseApiUrl}/group/user/` + email + `/` + page;
    return url;
  }

  getSyndicateHistoryByDate(date: string, page: number) {
    const url =
      `${environment.baseApiUrl}/group/syndicate/history/` + date + `/` + page;
    return url;
  }

  getSyndicateHistoryByDateRage(
    data: { dateFrom: string; dateTo: string; lotteryType: string },
    page: number
  ) {
    let dateFrom = data.dateFrom;
    let dateTo = data.dateTo;
    let lotteryType = data.lotteryType;
    const url = `${environment.baseApiUrl}/group/backup/${dateFrom}/${dateTo}/${lotteryType}/${page}`;
    return url;
  }

  addResult(syndicateId: string, resultData) {
    return this.http.patch<any>(
      `${environment.baseApiUrl}/group/result/` + syndicateId,
      resultData
    );
  }

  getCurrentTicketsOfUser(email: string, uid: string) {
    return this.http.get<any>(
      `${environment.baseApiUrl}/group/user/` + email + `/current/groups/` + uid
    );
  }

  getBackupTicketsOfUser(email: string, uid: string, page: number) {
    const url =
      `${environment.baseApiUrl}/group/user/` +
      email +
      `/backup/groups/` +
      uid +
      `/` +
      page;
    return url;
  }

  getGroupsWithTickets() {
    return this.http.get<any>(
      `${environment.baseApiUrl}/group/syndicate/current/tickets`
    );
  }

  getGroupsWithResults() {
    return this.http.get<any>(
      `${environment.baseApiUrl}/group/syndicate/current/results`
    );
  }

  getGroupsWithDAilyMillionResults() {
    return this.http.get<any>(
      `${environment.baseApiUrl}/group/syndicate/current/dailymillion/results`
    );
  }

  getGroupsWithoutResults() {
    return this.http.get<any>(
      `${environment.baseApiUrl}/group/syndicate/current/groups/without/results`
    );
  }

  getGroupsWithoutTicket() {
    return this.http.get<any>(
      `${environment.baseApiUrl}/group/groupid/groups/current`
    );
  }

  getGroupsWithDailyMillion() {
    return this.http.get<any>(
      `${environment.baseApiUrl}/group/groupid/groups/current/dailymillion`
    );
  }

  getCurrentGroupsCount() {
    return this.http.get<any>(
      `${environment.baseApiUrl}/group/get/user/current/count/today/count`
    );
  }

  addDailyMillionResult(groupid: string, result: { price: number }) {
    return this.http.patch<any>(
      `${environment.baseApiUrl}/group/dailyMillion/result/${groupid}`,
      result
    );
  }

  userShuffle() {
    return this.http.get<any>(`${environment.baseApiUrl}/group/usershuffle`);
  }
}

export default interface SyndicateData {
  startDate: string;
  endDate: string;
  lotteryType: string;
}
