import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { SplashScreen } from '@capacitor/splash-screen';
import { CAPACITOR } from 'src/app/constants/app.constants';

@Injectable()
export class SplashService {
  subject = new BehaviorSubject(false);
  constructor(private platform: Platform) {}
  async show(autohide?: boolean) {
    if (this.platform.is(CAPACITOR)) {
      await SplashScreen.hide();
      await SplashScreen.show({
        autoHide: autohide || false,
      });
    } else {
      this.subject.next(true);
    }
  }

  async hide() {
    if (this.platform.is(CAPACITOR)) {
      await SplashScreen.hide();
    } else {
      this.subject.next(false);
    }
  }
}
