import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ScreenSizeService {
  private isDesktop = new BehaviorSubject<boolean>(false);
  private screenSize = 800;
  constructor() {}

  onWindowResize(size) {
    if (size < this.screenSize) {
      this.isDesktop.next(false);
    } else {
      this.isDesktop.next(true);
    }
  }

  isDesktopView() {
    return this.isDesktop.asObservable().pipe(distinctUntilChanged());
  }
}
