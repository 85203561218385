// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseApiUrl: 'https://54.220.77.107',
  firebase: {
    apiKey: 'AIzaSyDJCkLmv3Gf55Xu6tfYjevicYL-QJH1zLA',
    authDomain: 'lottomax-my-syndicate.firebaseapp.com',
    projectId: 'lottomax-my-syndicate',
    storageBucket: 'lottomax-my-syndicate.appspot.com',
    messagingSenderId: '86524401909',
    appId: '1:86524401909:web:e169e371c3aa4b8e624dbc',
  },
  cmsApi: 'https://54.220.77.107/strapi',
  STRIPE_PK:
    'pk_test_51LsL4HHYxLlW8puXW50dfi8ds42HWBQcGQl27PvY3kExs1tzS33ktradLwJV6i3K4I93VKy5HqNr9ShCxnlhskbq00DhxWa1ds',
  STRIPE_PRODUCT_ID: {
    P1_PLAN_ID: {
      STRIPE_P1_MONTHLY: 'price_1M7vgGHYxLlW8puXx1tQX2R7',
      STRIPE_P1_HALF_YEARLY: 'price_1M7vgoHYxLlW8puXnt4OB2PU',
      STRIPE_P1_YEARLY: 'price_1M7vhFHYxLlW8puXiMKdELyJ',
    },
    P2_PLAN_ID: {
      STRIPE_P2_MONTHLY: 'price_1M7vkNHYxLlW8puXyuNVR5zF',
      STRIPE_P2_HALF_YEARLY: 'price_1M7vkwHYxLlW8puXAIEgnPnQ',
      STRIPE_P2_YEARLY: 'price_1M7vlxHYxLlW8puXMJqTP6ML',
    },
    P3_PLAN_ID: {
      STRIPE_P3_MONTHLY: 'price_1M7vncHYxLlW8puXeIXwSDBL',
      STRIPE_P3_HALF_YEARLY: 'price_1M7vmjHYxLlW8puXOcV1Jgio',
      STRIPE_P3_YEARLY: 'price_1M7vn0HYxLlW8puXppKQbUa4',
    },
  },
  SUBCRIPTION_PLANS: {
    P1: 'SILVER',
    P2: 'GOLD',
    P3: 'PLATINUM',
  },
  PAYPAL_CLIENT_ID:
    'AR52IU26qyKQRkEHLN5AQyLDPW1NtAuBMSSW3fCLvVzMsDp8XQUac83U2xGunIKUAmQ0Jo1Skyag4uDa',
  PAYPAL_PRODUCT_ID: {
    P1_PLAN_ID: {
      PAYPAL_P1_MONTHLY: 'P-3KC05147XR0666500MPCUATI',
      PAYPAL_P1_HALF_YEARLY: 'P-7RN35726TH596135UMPMOYXY',
      PAYPAL_P1_YEARLY: 'P-3JT95653P1178974DMPMO2GQ',
    },
    P2_PLAN_ID: {
      PAYPAL_P2_MONTHLY: 'P-2R0522486J9164834MPND5YI',
      PAYPAL_P2_HALF_YEARLY: 'P-56C10642142862116MPND6JA',
      PAYPAL_P2_YEARLY: 'P-8Y211544BW856072BMPND6WY',
    },
    P3_PLAN_ID: {
      PAYPAL_P3_MONTHLY: 'P-5BA06167BH273730KMPND7JA',
      PAYPAL_P3_HALF_YEARLY: 'P-1SN25389RL520882HMPND7YY',
      PAYPAL_P3_YEARLY: 'P-7Y279417CD943761SMPNEACQ',
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
