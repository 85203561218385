import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { LoadingService } from '../modules/shared/components/loading/loading.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(
    public loadingService: LoadingService,
    private authService: AuthService,
    public route: Router
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler) {
    this.loadingService.show();
    return next.handle(request).pipe(
      tap(
        (req) => {
          if (req instanceof HttpResponse) {
            this.loadingService.hide();
          }
        },
        (err) => {
          this.loadingService.hide();
        }
      )
    );
  }
}
