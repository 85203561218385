import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UserService } from '../modules/shared/services/user.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private userService: UserService,
    private authService: AuthService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to api url
    const user = this.userService.currentUser.value;
    const isLoggedIn = user.isAuthenticated;
    //const isApiUrl = request.url.startsWith(environment.apiUrl);
    if (isLoggedIn && request.url.indexOf('token=false') == -1) {
      request = request.clone({
        setHeaders: {
          ['Authorization']: `Bearer ${this.authService.getIdToken()}`,
        },
      });
    }
    return next.handle(request);
  }
}
