import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { LoadingComponent } from './modules/shared/components/loading/loading.component';
import { VerifyEmailComponent } from './modules/shared/components/verify-email/verify-email.component';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'news-details/:id',
    loadChildren: () =>
      import('./pages/news-details/news-details.module').then(
        (m) => m.NewsDetailsPageModule
      ),
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('./pages/contact-us/contact-us.module').then(
        (m) => m.ContactUsPageModule
      ),
  },
  {
    path: 'plans',
    loadChildren: () =>
      import('./pages/plans/plans.module').then((m) => m.PlansPageModule),
  },
  {
    path: 'disclaimer/:page',
    loadChildren: () =>
      import('./pages/disclaimer-page/disclaimer-page.module').then(
        (m) => m.DisclaimerPagePageModule
      ),
  },
  {
    path: 'no-network',
    loadChildren: () =>
      import('./pages/no-network/no-network.module').then(
        (m) => m.NoNetworkPageModule
      ),
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  { path: 'verify-email', component: VerifyEmailComponent },

  //admin section
  {
    path: 'admin',
    loadChildren: () =>
      import('./modules/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },
  //user section
  {
    path: 'user',
    loadChildren: () =>
      import('./modules/user/user.module').then((m) => m.UserModule),
    canActivate: [AuthGuard],
    data: {
      role: 'user',
    },
  },
  {
    path: 'un-authorized',
    loadChildren: () =>
      import('./pages/un-authorized/un-authorized.module').then(
        (m) => m.UnAuthorizedPageModule
      ),
  },
  {
    path: '404',
    loadChildren: () =>
      import('./pages/page-not-found/page-not-found.module').then(
        (m) => m.PageNotFoundPageModule
      ),
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
