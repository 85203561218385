import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Data } from '@angular/router';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert.service';
import { ToastService } from 'src/app/services/toast.service';
import { IUserDetail } from 'src/app/services/user-model/user-model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  currentUser = new BehaviorSubject<IUser>(initialUserData);
  constructor(private http: HttpClient, private alertService: AlertService) {}

  setCurrentUser(user: IUser) {
    this.currentUser.next(user);
  }

  hasPermission(role: 'admin' | 'user' | 'anonymous' | null) {
    return role === null || this.currentUser.value.role === role;
  }

  async findUserDetails(email: string) {
    return await this.http
      .get<any>(`${environment.baseApiUrl}/users/` + email)
      .toPromise();
  }

  async findUserAddress(email: string) {
    return await this.http
      .get<any>(`${environment.baseApiUrl}/users/` + email + `/address`)
      .toPromise();
  }

  async userSubscribe(email: string, data) {
    return this.http
      .post<any>(
        `${environment.baseApiUrl}/users/` + email + `/subscription`,
        data
      )
      .subscribe((data) => {});
  }

  async getAllUsers(page: number) {
    const url = `${environment.baseApiUrl}/users/allusers/page/${page}`;
    return url;
  }

  async getUserListByCondition(condition: string, page: number) {
    const url = `${environment.baseApiUrl}/users/sort/user/by/${condition}/manage/users/list/${page}`;
    return url;
  }

  async updateProfile(email: string, data) {
    return await this.http.patch<any>(
      `${environment.baseApiUrl}/users/` + email,
      data
    );
  }

  async updateInternalUserStatus(email: string, status: boolean) {
    return await this.http.patch<any>(
      `${environment.baseApiUrl}/users/${email}/user/internal-user/status/${status}`,
      null
    );
  }

  public uploadfile(data, file: File) {
    let email = data.email;
    let name = data.document;
    let formParams = new FormData();
    formParams.append('file', file);
    formParams.append('name', name);
    return this.http.post(
      `${environment.baseApiUrl}/users/` + email + `/document/upload`,
      formParams
    );
  }

  getDocument(email: string) {
    return this.http.get(
      `${environment.baseApiUrl}/users/` + email + `/document`
    );
  }

  getUserCount() {
    return this.http.get(
      `${environment.baseApiUrl}/users/all/user/count/admin/get`
    );
  }

  uploadUserProfilePicture(email: string, image: File) {
    let imageData = new FormData();
    imageData.append('file', image);
    return this.http.post<any>(
      `${environment.baseApiUrl}/users/${email}/image`,
      imageData
    );
  }

  removeProfilePhoto(email: string) {
    return this.http.delete(`${environment.baseApiUrl}/users/${email}/image`);
  }

  getUserDocumentUrl(email: string, id: number) {
    return this.http.get(
      `${environment.baseApiUrl}/users/${email}/document/${id}`
    );
  }

  deleteUserDocumentUrl(email: string, id: number) {
    return this.http.delete(
      `${environment.baseApiUrl}/users/${email}/document/${id}`
    );
  }

  activateUser(email: string, plan: string) {
    return this.http.patch(
      `${environment.baseApiUrl}/users/${email}/activate/${plan}`,
      null
    );
  }

  deActivateUser(email: string) {
    return this.http.patch(
      `${environment.baseApiUrl}/users/${email}/deactivate`,
      null
    );
  }

  searchUser(searchData: string, page: number) {
    const url = `${environment.baseApiUrl}/users/allusers/${searchData}/page/${page}`;
    return url;
  }

  sendOtp(email: string, recaptcha: string, phoneNumber: string) {
    let body = {
      email: email,
      recaptcha: recaptcha,
      phoneNumber: phoneNumber,
    };
    return this.http.post(
      `${environment.baseApiUrl}/users/send-otp/user/verification/message/otp/firebase`,
      body
    );
  }

  clearSubscriptionBalance(email: string) {
    return this.http.patch(
      `${environment.baseApiUrl}/users/users/clear/subscription/balance/of/${email}`,
      null
    );
  }

  verifyOtp(email: string, code: string) {
    let body = {
      email: email,
      code: code,
    };
    return this.http.post(
      `${environment.baseApiUrl}/users/verify-otp/user/verification/message/otp/firebase/confirm`,
      body
    );
  }

  verifyEmail(email: string, uid: string) {
    return this.http.get<any>(
      `${environment.baseApiUrl}/mail/${email}/verify/${uid}`
    );
  }

  async checkNotificationToken(email: string) {
    return this.http
      .get<any>(
        `${environment.baseApiUrl}/users/check/notification/token/${email}`
      )
      .toPromise();
  }
}

export const initialUserData: IUser = {
  displayName: 'anonymous',
  email: undefined,
  id: undefined,
  role: 'anonymous',
  isAuthenticated: false,
  photoUrl: '',
  emailVerified: false,
  userDetails: null,
};

export default interface IUser {
  id: string;
  email: string;
  displayName: string;
  isAuthenticated: boolean;
  emailVerified: boolean;
  role: 'admin' | 'user' | 'anonymous';
  photoUrl?: string;
  emailClaim?: string;
  userDetails?: IUserDetail;
}
