import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { INavConfig } from 'src/app/modules/shared/components/nav/nav.component';
import { ScreenSizeService } from 'src/app/modules/shared/services/screen-size.service';
import adminNavJson from '../../../config/admin.menu.json';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  navConfig: INavConfig;
  subscription: Subscription;
  desktop = false;
  constructor(private screenSizeService: ScreenSizeService) {}

  get isDesktop() {
    return this.screenSizeService.isDesktopView();
  }

  ngOnInit() {
    this.initNavConfig();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private initNavConfig(): void {
    this.navConfig = adminNavJson as INavConfig;
    this.subscription = this.screenSizeService
      .isDesktopView()
      .subscribe((value) => {
        this.desktop = value;
      });
  }
}
