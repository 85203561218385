import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ToastService } from 'src/app/services/toast.service';
import { environment } from 'src/environments/environment';
import { PaymentService } from '../../services/payment.service';
import IUser, { UserService } from '../../services/user.service';
declare var Stripe;

declare var RapydCheckoutToolkit: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit {
  @Input()
  planDetails: {
    plan: string;
    type: any;
    planId: string;
    planPrice: number;
    planName: string;
  };

  stripe = Stripe(environment.STRIPE_PK);
  elements = this.stripe.elements();
  plan: string;
  type: string;
  user: IUser;

  card = this.elements.create('card');
  planId: any;

  message: string;

  constructor(
    private userService: UserService,
    private paymentService: PaymentService,
    private toastService: ToastService,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.setupStripe();
    this.getPlanDetails();
    this.getUser();
    this.setupRapyd();
  }

  @ViewChild('nameInput')
  nameInput: any;

  async getUser() {
    const user = await this.userService.currentUser.getValue();
    this.user = user;
  }

  async getPlanDetails() {
    this.plan = this.planDetails.plan;
    this.type = this.planDetails.type;
    this.planId = this.planDetails.planId;
  }

  async setupRapyd() {
    window.onload = function () {
      let checkout = new RapydCheckoutToolkit({
        pay_button_text: 'Click to pay',
        pay_button_color: 'blue',
        id: 'checkout_9ebe58dcb9d75e8f972a35350f96c2fa',
        style: {
          submit: {
            base: {
              color: 'white',
            },
          },
        },
      });
      checkout.displayCheckout();
    };
    window.addEventListener('onCheckoutPaymentSuccess', function (event: any) {
      // console.log(event.detail);
    });
    window.addEventListener('onCheckoutPaymentFailure', function (event: any) {
      // console.log(event.detail.error);
    });

    function feedback(event) {
      if (event.detail.error) {
        document.getElementById('title').textContent = 'Whoops!';
        document.getElementById('message').innerHTML =
          'We cannot process your payment:<br/>' + event.detail.error;
        (document.getElementById('image') as any).src = 'img/no-bike.svg';
        document.getElementById('action').textContent = 'Try again';
      } else {
        document.getElementById('title').textContent = 'Success!';
        document.getElementById('message').innerHTML =
          'Thank you! Your product is on its way!' +
          '<br>' +
          'Order: ' +
          event.detail.metadata.sales_order;
        (document.getElementById('image') as any).src = 'img/logo.svg';
        document.getElementById('action').textContent = 'Home';
      }

      (document.getElementById('action') as any).href = 'bike.html';
      document.getElementById('feedback').style.display = 'block';
    }
  }

  setupStripe() {
    let elements = this.stripe.elements();
    var style = {
      base: {
        color: '#32325d',
        lineHeight: '50px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    };

    this.card = elements.create('card', { style: style });
    this.card.mount('#card-element');

    this.card.addEventListener('change', (event) => {
      var displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    var form = document.getElementById('payment-form');
    form.addEventListener('submit', (event) => {
      event.preventDefault();
      this.stripe.createSource(this.card).then((result) => {
        if (result.error) {
          var errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
        } else {
          this.makePayment(result);
        }
      });
    });
  }

  makePayment(token) {
    this.modalController.dismiss({
      dismissed: true,
    });
    let customerDetails = {
      email: this.user.email,
      plan: this.plan,
      type: this.type,
      planId: this.planId,
    };
    this.paymentService
      .createCustomer(
        customerDetails,
        token.source.id,
        this.nameInput.nativeElement.value
      )
      .subscribe(
        (data) => {
          this.toastService.toast('You have been successfully subscribed');
        },
        (error) => {
          this.toastService.toast(error);
        }
      );
  }

  onClose() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
