import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  notificationCount = new BehaviorSubject<number>(0);

  constructor(private http: HttpClient) {}
  initPush(email: string) {
    if (Capacitor.getPlatform() !== 'web') {
      this.registerPush(email);
    }
  }
  private registerPush(email: string) {
    PushNotifications.requestPermissions().then((permission) => {
      if (permission.receive === 'granted') {
        PushNotifications.register();
      } else {
        // If permission is not granted
      }
    });
    PushNotifications.addListener('registration', (token) => {
      // console.log('token', token);
      let body = {
        token: token.value,
      };
      this.http
        .post<any>(
          `${environment.baseApiUrl}/notification/${email}/add/addtoken/tokenId`,
          body
        )
        .subscribe(
          (res) => {},
          (error) => {}
        );
    });
    PushNotifications.addListener('registrationError', (err) => {
      // console.log(err);
    });
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notifications) => {
        // console.log(notifications);
      }
    );
  }

  getNotificationsByEmail(email: string) {
    return this.http.get<any>(
      `${environment.baseApiUrl}/notification/${email}`
    );
  }

  getNotificationCountByEmail(email: string) {
    return this.http.get<any>(
      `${environment.baseApiUrl}/notification/${email}/count`
    );
  }

  deleteNotificationById(id: number) {
    return this.http.delete<any>(
      `${environment.baseApiUrl}/notification/${id}/remove`
    );
  }

  sendContactUsEmail(data) {
    return this.http.post<any>(
      `${environment.baseApiUrl}/mail/contact/email`,
      data
    );
  }

  updateNotificationReadStatus(id: number) {
    return this.http.get<any>(
      `${environment.baseApiUrl}/notification/update/notification/status/${id}`
    );
  }
}
