import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss'],
})
export class NumberComponent implements OnInit {
  @Input()
  value: string;

  @Input()
  backgroundColor: string;

  @Input()
  showStar: boolean;

  backgroundImage = '';

  constructor() { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    const prop = changes.backgroundColor;
    const dark =
      this.backgroundColor.substring(0, this.backgroundColor.length - 2) + '99';
    if (prop.currentValue) {
      this.backgroundImage = `linear-gradient(45deg,${this.backgroundColor} 0,${this.backgroundColor} 50%,${dark} 51%,${dark} 100%)`;
    }
  }

}
