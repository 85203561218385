import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-payout-admin-form',
  templateUrl: './payout-admin-form.component.html',
  styleUrls: ['./payout-admin-form.component.scss'],
})
export class PayoutAdminFormComponent implements OnInit {
  @Input()
  transactionId: number;

  status: string;
  comment: string;
  payoutData: {
    status: string;
    comment?: string;
  };

  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  cancel() {
    this.payoutData = {
      status: 'incompleted',
      comment: this.comment,
    };
    this.modalController.dismiss({
      dismissed: true,
      data: this.payoutData,
    });
  }

  approve() {
    this.payoutData = {
      status: 'completed',
      comment: this.comment,
    };
    this.modalController.dismiss({
      dismissed: true,
      data: this.payoutData,
    });
  }

  onClose() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
