import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { SignupComponent } from 'src/app/components/signup/signup.component';
import { Wallet } from 'src/app/services/content-model/wallet-model/wallet-model';
import { environment } from 'src/environments/environment';
import IUser, { UserService } from '../../services/user.service';
import { WalletService } from '../../services/wallet.service';
import { PayoutFormComponent } from '../payout-form/payout-form.component';

@Component({
  selector: 'app-wallet-card',
  templateUrl: './wallet-card.component.html',
  styleUrls: ['./wallet-card.component.scss'],
})
export class WalletCardComponent implements OnInit {
  documentsPresent = true;
  wallet: Wallet = {
    balanceAmount: 0,
    lastWinningAmount: 0,
    totalWinnings: 0,
  };
  email: string;
  subscriptionPlan: string;
  user: IUser;

  constructor(
    private alertController: AlertController,
    private modalController: ModalController,
    private walletService: WalletService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.getUser();
  }

  async getUser() {
    this.user = await this.userService.currentUser.getValue();
    return this.getUserWallet(this.user, this.user.email);
  }

  async getUserWallet(user: IUser, email: string) {
    const wallet = await this.walletService.getUserWallet(email).subscribe(
      async (wallet) => {
        this.email = wallet?.email;
        const plan = user?.userDetails?.subscription?.plan;
        this.subscriptionPlan = await this.getPlanName(plan);
        this.wallet = {
          balanceAmount: wallet?.balanceAmount,
          lastWinningAmount: wallet?.lastWinningAmount,
          totalWinnings: wallet?.totalAmount,
        };
      },
      async (err) => {
        const plan = user?.userDetails?.subscription?.plan;
        this.subscriptionPlan = await this.getPlanName(plan);
      }
    );
    return wallet;
  }

  getPlanName(plan: string): string {
    if (plan == 'P1') {
      return environment.SUBCRIPTION_PLANS.P1;
    } else if (plan == 'P2') {
      return environment.SUBCRIPTION_PLANS.P2;
    } else if (plan == 'P3') {
      return environment.SUBCRIPTION_PLANS.P3;
    } else {
      return 'NOT SUBSCRIBED';
    }
  }

  async showRegister(balanceAmount: number) {
    const signupModal = await this.modalController.create({
      component: PayoutFormComponent,
      cssClass: 'modal-popup-form',
      componentProps: { balance: balanceAmount },
    });

    return signupModal.present();
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      header: 'Documents needed!',
      mode: 'ios',
      message:
        'Please upload your ID Proof and Address Proof for submit payout request',
      buttons: ['OK'],
    });

    await alert.present();
  }

  onClickPayout() {
    if (this.user.userDetails.kycStatus == true) {
      this.showRegister(this.wallet.balanceAmount);
    } else {
      this.presentAlert();
    }
  }
}
