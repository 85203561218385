import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoadingService } from '../modules/shared/components/loading/loading.service';
import { UserService } from '../modules/shared/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private router: Router,
    private loadingService: LoadingService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const expectedRole = route.data?.role || null;

    let currentUser = this.userService.currentUser.getValue();
    // return this.userService.currentUser.pipe(
    //   map((user) => {
    //     if (expectedRole !== null) {
    //       if (user.role !== expectedRole) {
    //         this.router.navigate(['/un-authorized']);
    //         return false;
    //       }
    //       return true;
    //     }
    //     return true;
    //   })
    //);
    const isUnAuthorized = currentUser.role !== expectedRole;
    if (isUnAuthorized) {
      this.loadingService.show();
      setTimeout(() => {
        this.loadingService.hide();
        // this.router.navigate(['/un-authorized']);
      }, 5000);
    }
    return !isUnAuthorized;
  }
}
