import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private alertController: AlertController) {}

  async alert(header: string) {
    const alert = await this.alertController.create({
      mode: 'ios',
      header: header,
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          handler: () => {
            this.closeAlert();
          },
        },
      ],
    });
    return await alert.present();
  }

  async closeAlert() {
    return await this.alertController.dismiss();
  }
}
