import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastController: ToastController) {}

  async toast(message: string) {
    const toast = await this.toastController.create({
      mode: 'ios',
      duration: 5000,
      position: 'bottom',
      message: message,
      color: 'light',
    });
    toast.present();
  }
}
