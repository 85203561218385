import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PayoutService {
  constructor(private http: HttpClient) {}

  payoutRequest(payoutData: PayoutRequest) {
    return this.http.post<any>(`${environment.baseApiUrl}/payout`, payoutData);
  }

  getPayoutRequests(page: number) {
    const url = `${environment.baseApiUrl}/payout/request/` + page;
    return url;
  }

  getPayoutRequestsHistory(page: number) {
    const url = `${environment.baseApiUrl}/payout/backup/${page}`;
    return url;
  }

  updatePayoutRequest(
    transactionId: number,
    status: string,
    comment: { comment: string }
  ) {
    return this.http.patch<any>(
      `${environment.baseApiUrl}/payout/status/` + transactionId + `/` + status,
      comment
    );
  }

  updatePayoutRequestCompleteStatus(
    transactionId: number,
    status: string,
    comment: { comment: string }
  ) {
    return this.http.patch<any>(
      `${environment.baseApiUrl}/payout/backuppayout/status/${transactionId}/${status}`,
      comment
    );
  }

  getPayoutHistoryByEmail(email: string, page: number) {
    const url = `${environment.baseApiUrl}/payout/backup/user/${email}/get/${page}`;
    return url;
  }

  getPayoutHistoryByDate(date: string, page: number) {
    const url = `${environment.baseApiUrl}/payout/backup/${date}/page/${page}`;
    return url;
  }
}

export interface PayoutRequest {
  email: string;
  accountName: string;
  iBan: string;
  amount: number;
}

export interface PayoutRequestTable {
  date: Date;
  email: string;
  accountName: string;
  iBan: string;
  amount: number;
  transactionId: number;
}
