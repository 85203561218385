import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { IonInput, ModalController, ToastController } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';
import { LoadingService } from 'src/app/modules/shared/components/loading/loading.service';
import { ScreenSizeService } from 'src/app/modules/shared/services/screen-size.service';
import { AuthService } from 'src/app/services/auth.service';
import { Register } from 'src/app/services/user-model/register-model';
import { LoginComponent } from '../login/login.component';
import { UserService } from 'src/app/modules/shared/services/user.service';
import { disclaimerUrl } from 'src/app/constants/disclaimer-url';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  termsAndConditionsUrl = disclaimerUrl.termsAndConditions;
  userAgreementUrl = disclaimerUrl.userAgreement;
  @ViewChild('password')
  password: IonInput;

  @ViewChild('confirmPassword')
  confirmPassword: IonInput;

  successMsg = '';
  errorMsg = '';
  showPassword = false;
  showConfirmPassword = false;

  error = {
    email: [
      {
        type: 'required',
        message: 'Provide email.',
      },
      {
        type: 'pattern',
        message: 'Email is not valid.',
      },
    ],
    password: [
      {
        type: 'required',
        message: 'Password is required.',
      },
      {
        type: 'minlength',
        message: 'Password length should be 8 characters long.',
      },
      {
        type: 'pattern',
        message:
          'Your password should be at least 8 characters long and should contain one uppercase letter, one lowercase letter and one symbol',
      },
    ],
    confirmPassword: [
      {
        type: 'required',
        message: 'Confirm-password is required.',
      },
      {
        type: 'minlength',
        message: 'Confirm-password length should be 8 characters long.',
      },
      {
        type: 'pattern',
        message:
          'Your password should be at least 8 characters long and should contain one uppercase letter, one lowercase letter and one symbol',
      },
    ],
  };
  register: Register;
  submitButton = false;
  internalUserIcon: boolean;
  internalUser: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private loadingService: LoadingService,
    private authService: AuthService,
    private screenSizeService: ScreenSizeService,
    private toastController: ToastController,
    private modalController: ModalController,
    private alertService: AlertService,
    private userService: UserService
  ) {}

  get isDesktop() {
    return this.screenSizeService.isDesktopView();
  }

  ngOnInit() {
    const userRole = this.userService.currentUser.getValue().role;
    if (userRole == 'admin') {
      this.internalUserIcon = true;
    }
  }
  userForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.pattern(
        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
      ),
    ]),
    confirmPassword: new FormControl('', [
      ,
      Validators.required,
      Validators.pattern(
        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
      ),
    ]),
  });

  checkBoxChange(isChecked) {
    this.submitButton = isChecked;
  }

  naviagateTo(url: string) {
    this.modalController.dismiss({
      dismissed: true,
    });
    this.router.navigate([url]);
  }

  internalUserChecked(value) {
    this.internalUser = value;
  }

  async signUp(value: any) {
    let password = this.userForm.controls.password.value;
    let confirmPassword = this.userForm.controls.confirmPassword.value;
    if (password != confirmPassword) {
      this.alertService.alert(`Password and Confirm password doesn't match!`);
    } else {
      if (!this.internalUser) {
        this.internalUser = false;
      }
      this.register = {
        email: value.email,
        password: value.password,
        confirmPassword: value.confirmPassword,
        internalUser: this.internalUser,
      };
      this.authService.register(this.register);
      this.modalController.dismiss({
        dismissed: true,
      });
    }
  }

  async goToSigin() {
    this.onClose();
    const loginModal = await this.modalController.create({
      component: LoginComponent,
      cssClass: this.isDesktop ? 'modal-popup-login' : 'modal-popup-mobile',
    });
    await loginModal.present();
    return false;
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
    this.password.type = this.showPassword ? 'text' : 'password';
  }

  toggleConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
    this.confirmPassword.type = this.showConfirmPassword ? 'text' : 'password';
  }

  onClose() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  goToDisclaimer(page: string) {
    this.modalController.dismiss({
      dismissed: true,
    });
    if (page == 'agreement') {
      this.router.navigate([`/disclaimer/` + this.userAgreementUrl]);
    } else if (page == 'terms') {
      this.router.navigate([`/disclaimer/` + this.termsAndConditionsUrl]);
    }
  }

  private confirmPasswordValidator(
    controlName: string,
    matchingControlName: string
  ) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.confirmPassword) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmPassword: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
}
