import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CmsService } from 'src/app/services/cms.service';
import { News } from 'src/app/services/content-model/news-model';
import { environment } from 'src/environments/environment';
import { ScreenSizeService } from '../../services/screen-size.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {
  strapiUrl = environment.cmsApi;
  news: {
    title: string;
    content: string;
    image: string;
  };
  private screenSize = 768;
  subscription: any;

  desktop = false;

  constructor(
    private router: Router,
    private contentApi: CmsService,
    private screenSizeService: ScreenSizeService
  ) {}

  ngOnInit() {
    this.loadNews();
    this.setSliderCount(window.innerWidth);
  }

  loadNews() {
    this.contentApi.news().subscribe((response: any) => {
      this.news = response?.data.map((res: any) => {
        return {
          id: res.id,
          title: res?.attributes?.title,
          content: res?.attributes?.content,
          image: res?.attributes?.image?.data,
        };
      });
    });
  }

  viewDetailNews(id: number) {
    this.router.navigate([`/news-details/${id}`]);
  }

  options = {
    initialSlide: 1,
    slidesPerView: 1,
    speed: 400,
    loop: true,
    spaceBetween: 5,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };

  private initNavConfig(): void {
    this.subscription = this.screenSizeService
      .isDesktopView()
      .subscribe((value) => {
        this.options = { ...this.options, slidesPerView: 4 };
      });
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.setSliderCount(event.target.innerWidth);
  }

  private setSliderCount(width: number) {
    if (width < this.screenSize) {
      this.options = { ...this.options, slidesPerView: 1 };
    } else {
      this.options = { ...this.options, slidesPerView: 3 };
    }
  }

  onClick() {
    this.router.navigate(['/news-details']);
  }
}
