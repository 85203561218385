export const sideNavItems = {
  home: 'home',
  password: 'changePassword',
  logout: 'logout',
  profile: 'profile',
  ticket: 'ticket',
  wallet: 'wallet',
  transaction: 'transaction',
  notifications: 'notifications',
  ticketUpload: 'ticketUpload',
  uploadResults: 'uploadResults',
};
