import { Component, Input, OnInit } from '@angular/core';
import { euroMillions } from 'src/app/services/content-model/euro-millions';
import { irishLotto } from 'src/app/services/content-model/irish-lotto';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss'],
})
export class ResultComponent implements OnInit {
  strEM = 'Euro Millions';
  strLotto = 'Lotto';

  euroSize = '27px';
  lottoSize = '37px';
  @Input()
  euroMillions: euroMillions;
  @Input()
  irishLotto: irishLotto;

  constructor() {}

  ngOnInit() {}
}
