import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { IonInput, ModalController, Platform } from '@ionic/angular';
import { SignupComponent } from 'src/app/components/signup/signup.component';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast.service';
import { PayoutRequest, PayoutService } from '../../services/payout.service';
import { ScreenSizeService } from '../../services/screen-size.service';
import { UserService } from '../../services/user.service';
import { LoadingService } from '../loading/loading.service';

@Component({
  selector: 'app-payout-form',
  templateUrl: './payout-form.component.html',
  styleUrls: ['./payout-form.component.scss'],
})
export class PayoutFormComponent implements OnInit {
  @ViewChild('password')
  password: IonInput;

  @Input()
  balance: string;

  email: string;

  payoutForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private loadingService: LoadingService,
    private screenSizeService: ScreenSizeService,
    private modalController: ModalController,
    private toastService: ToastService,
    private payoutService: PayoutService,
    private userService: UserService,
    public platform: Platform,
    private alertService: AlertService
  ) {}

  get isDesktop() {
    return this.screenSizeService.isDesktopView();
  }

  ngOnInit() {
    this.email = this.userService.currentUser.getValue().email;
    this.payoutForm = this.fb.group({
      amount: new FormControl('', Validators.compose([Validators.required])),
      account: new FormControl('', Validators.compose([Validators.required])),
      iban: new FormControl('', Validators.compose([Validators.required])),
    });
  }

  get f() {
    return this.payoutForm.controls;
  }

  async submitRequest(value) {
    if (this.payoutForm.invalid) {
      for (const control of Object.keys(this.payoutForm.controls)) {
        this.payoutForm.controls[control].markAsTouched();
      }
      this.toastService.toast('Please enter mandatory fields!');
      return;
    } else {
      if (value.amount > this.balance) {
        this.toastService.toast('Please enter a amount less than balance!');
      } else if (value.amount <= 0) {
        this.toastService.toast('Please enter correct amount!');
      } else {
        this.onClose();
        let payoutData: PayoutRequest = {
          accountName: value.account,
          amount: value.amount,
          email: this.email,
          iBan: value.iban,
        };
        this.payoutService.payoutRequest(payoutData).subscribe(
          (response) => {
            this.alertService.alert('Payout request successfully submitted!');
          },
          (error) => {
            this.toastService.toast(error);
          }
        );
      }
    }
  }
  onClose() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
