import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { INavConfig } from 'src/app/modules/shared/components/nav/nav.component';
import { ScreenSizeService } from 'src/app/modules/shared/services/screen-size.service';
import generalNavJson from '../../../config/general.menu.json';
import { LoginComponent } from '../../login/login.component';
import { SignupComponent } from '../../signup/signup.component';

@Component({
  selector: 'app-generic-layout',
  templateUrl: './generic-layout.component.html',
  styleUrls: ['./generic-layout.component.scss'],
})
export class GenericLayoutComponent implements OnInit, OnDestroy {
  navConfig: INavConfig;
  subscription: Subscription;
  desktop = false;
  constructor(
    private screenSizeService: ScreenSizeService,
    private modalController: ModalController
  ) {}

  get isDesktop() {
    return this.screenSizeService.isDesktopView();
  }

  ngOnInit() {
    this.initNavConfig();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  async showRegister() {
    const signupModal = await this.modalController.create({
      component: SignupComponent,
      cssClass: this.desktop ? 'modal-popup-login' : 'modal-popup-mobile',
    });
    return signupModal.present();
  }

  async showLogin() {
    const loginModal = await this.modalController.create({
      component: LoginComponent,
      cssClass: this.desktop ? 'modal-popup-login' : 'modal-popup-mobile',
    });
    return loginModal.present();
  }

  private initNavConfig(): void {
    this.navConfig = generalNavJson as INavConfig;
    this.subscription = this.screenSizeService
      .isDesktopView()
      .subscribe((value) => {
        this.desktop = value;
      });
  }
}
