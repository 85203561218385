import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { DatePipe } from '@angular/common';
import SyndicateData, {
  SyndicateService,
} from '../../services/syndicate.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss'],
})
export class FilterModalComponent implements OnInit {
  date: string;
  lotteryTypes: string[] = ['IRISH LOTTO', 'EURO MILLION', 'DAILY MILLION'];

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
    radio: new FormControl('', [Validators.required]),
  });

  isModalOpen: boolean;

  data: any;

  constructor(
    private modalController: ModalController,
    public datepipe: DatePipe,
    private toastService: ToastService
  ) {}

  ngOnInit() {}

  setDate(date) {
    this.date = date;
  }

  onClick(value) {
    if (this.range.invalid) {
      for (const control of Object.keys(this.range.controls)) {
        this.range.controls[control].markAsTouched();
      }
      this.toastService.toast('Please enter mandatory fields!');
      return;
    } else {
      let dateFrom = this.datepipe.transform(value.start, 'yyyy-MM-dd');
      let dateTo = this.datepipe.transform(value.end, 'yyyy-MM-dd');
      let lotteryType = value.radio;
      let syndicateData: SyndicateData = {
        startDate: dateFrom,
        endDate: dateTo,
        lotteryType: lotteryType,
      };
      this.modalController.dismiss({
        dismissed: true,
        filterForm: syndicateData,
      });
    }
  }

  onClose() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
