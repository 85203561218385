import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingService {
  subject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor() {}
  get loader() {
    return this.subject;
  }
  show() {
    this.subject.next(true);
  }

  hide() {
    this.subject.next(false);
  }
}
