import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { SubscriptionModel } from 'src/app/services/user-model/subscription-model';
import { environment } from 'src/environments/environment';
import { PaymentService } from '../../services/payment.service';
import IUser, { UserService } from '../../services/user.service';

@Component({
  selector: 'app-subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrls: ['./subscription-plan.component.scss'],
})
export class SubscriptionPlanComponent implements OnInit {
  user: IUser;
  userSubscription: SubscriptionModel;
  plan: string;
  isPaypal = false;

  constructor(
    private modalController: ModalController,
    private alertController: AlertController,
    private paymentService: PaymentService,
    private userService: UserService
  ) {}

  ngOnInit() {}

  ionViewWillEnter() {
    this.getUser();
  }

  async getUser() {
    const userData = await this.userService.currentUser.getValue();
    this.user = userData;
    this.userSubscription = {
      plan: userData?.userDetails?.subscription?.plan,
      type: userData?.userDetails?.subscription?.type,
      startDate: userData?.userDetails?.subscription?.startDate,
      endDate: userData?.userDetails?.subscription?.endDate,
      isActive: userData?.userDetails?.subscription?.isActive,
      gateway: userData?.userDetails?.subscription?.gateway,
    };
    this.isPaypal = await this.getGatewayStatus(this.userSubscription?.gateway);
    if (this.userSubscription.plan == 'P1') {
      this.plan = environment.SUBCRIPTION_PLANS.P1;
    } else if (this.userSubscription.plan == 'P2') {
      this.plan = environment.SUBCRIPTION_PLANS.P2;
    } else if (this.userSubscription.plan == 'P3') {
      this.plan = environment.SUBCRIPTION_PLANS.P3;
    }
  }

  getGatewayStatus(plan: string): boolean {
    if (plan == 'paypal') {
      return true;
    } else if (plan == 'rapyd') {
      return false;
    }
  }

  onClose() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  async confirmAlert() {
    const alert = await this.alertController.create({
      header: 'Are you sure you want to cancel your subscription?',
      cssClass: 'custom-alert',
      mode: 'ios',
      buttons: [
        {
          text: 'No',
          cssClass: 'alert-button-cancel',
        },
        {
          text: 'Yes',
          cssClass: 'alert-button-confirm',
          handler: () => {
            this.cancelSubscription();
          },
        },
      ],
    });

    await alert.present();
  }

  async cancelSubscription() {
    this.onClose();
    let email = this.user.email;
    let uid = this.user.userDetails.uid;
    return await this.paymentService.cancelSubscription(email, uid);
  }
}
